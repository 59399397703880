import React, { useState, useEffect } from 'react';
import { ref, onValue, push, set, remove, update } from 'firebase/database';
import { database } from './config/firebase';
import PropTypes from 'prop-types';
import { PlusCircle, X, Calendar, Square, CheckSquare, Edit2, Trash2 } from 'lucide-react';
import ConfirmDialog from './ConfirmDialog'; // ConfirmDialogをインポート

const HomeworkByDate = ({ selectedStudent, currentUser }) => {
  const [homeworkByDate, setHomeworkByDate] = useState({});
  const [isAddingDate, setIsAddingDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [addingHomeworkDate, setAddingHomeworkDate] = useState(null);
  const [editingDate, setEditingDate] = useState(null);
  const [newDateValue, setNewDateValue] = useState('');
  const [error, setError] = useState('');

  const [editingTaskId, setEditingTaskId] = useState(null);
  const [editTaskSubject, setEditTaskSubject] = useState('');
  const [editTaskContent, setEditTaskContent] = useState('');
  const [editTaskDueDate, setEditTaskDueDate] = useState('');
  const [editTaskComment, setEditTaskComment] = useState('');
  const [taskError, setTaskError] = useState('');

  // 新しい宿題の状態
  const [newHomework, setNewHomework] = useState({
    subject: '',
    content: '',
    dueDate: '',
    teacherComment: '',
  });

  // ConfirmDialog用の状態
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState('');
  const [confirmMessage, setConfirmMessage] = useState('');
  const [onConfirmAction, setOnConfirmAction] = useState(() => {});

  // 教科のリストを定義
  const subjects = ['英語', '数学', '算数', '国語', '理科', '社会', 'その他'];

  // 生徒IDの決定
  const studentId = currentUser?.role === 'student' ? currentUser.id : selectedStudent?.id;

  // 曜日の取得
  const getJapaneseDayOfWeek = (dateStr) => {
    const days = ['日', '月', '火', '水', '木', '金', '土'];
    const date = new Date(dateStr);
    return days[date.getDay()];
  };

  // 日付のフォーマット（例: "3月15日(水)"）
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = getJapaneseDayOfWeek(dateStr);
    return `${month}月${day}日(${dayOfWeek})`;
  };

  // 期限のフォーマット（例: "3月15日(水)まで"）
  const formatDueDate = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dayOfWeek = getJapaneseDayOfWeek(dateStr);
    return `${month}月${day}日(${dayOfWeek})まで`;
  };

  // データの取得
  useEffect(() => {
    if (!studentId) {
      setHomeworkByDate({});
      return;
    }

    const homeworkRef = ref(database, `homework/${studentId}`);
    const unsubscribe = onValue(homeworkRef, (snapshot) => {
      const data = snapshot.val() || {};
      setHomeworkByDate(data);
    });

    return () => unsubscribe();
  }, [studentId]);

  // 宿題の追加
  const handleAddHomework = async (date) => {
    if (!newHomework.subject.trim() || !newHomework.content.trim()) {
      alert('教科と内容を入力してください。');
      return;
    }

    const homeworkRef = push(ref(database, `homework/${studentId}/${date}/tasks`));
    await set(homeworkRef, {
      id: homeworkRef.key,
      subject: newHomework.subject,
      content: newHomework.content,
      dueDate: newHomework.dueDate,
      completed: false,
      teacherCheck: null,
      teacherComment: newHomework.teacherComment || '',
    });

    setNewHomework({ subject: '', content: '', dueDate: '', teacherComment: '' });
    setAddingHomeworkDate(null);
  };

  // 宿題の編集
  const handleUpdateHomework = async (date, homeworkId, updatedHomework) => {
    await update(ref(database, `homework/${studentId}/${date}/tasks/${homeworkId}`), updatedHomework);
    setEditingTaskId(null);
    setEditTaskSubject('');
    setEditTaskContent('');
    setEditTaskDueDate('');
    setEditTaskComment('');
    setTaskError('');
  };

  // 宿題の削除（ConfirmDialogを使用）
  const handleDeleteHomework = (date, homeworkId) => {
    setConfirmTitle('宿題の削除確認');
    setConfirmMessage('この宿題を削除してもよろしいですか？');
    setOnConfirmAction(() => async () => {
      await remove(ref(database, `homework/${studentId}/${date}/tasks/${homeworkId}`));
      setIsConfirmOpen(false);
    });
    setIsConfirmOpen(true);
  };

  // 日付ごとの宿題を一括で削除（ConfirmDialogを使用）
  const handleDeleteDate = (date) => {
    setConfirmTitle('日付ごとの宿題削除確認');
    setConfirmMessage(`"${formatDate(date)}"のすべての宿題を削除してもよろしいですか？`);
    setOnConfirmAction(() => async () => {
      await remove(ref(database, `homework/${studentId}/${date}`));
      setIsConfirmOpen(false);
    });
    setIsConfirmOpen(true);
  };

  // 完了状態の切り替え
  const handleToggleComplete = async (date, homeworkId, currentState) => {
    await update(ref(database, `homework/${studentId}/${date}/tasks/${homeworkId}`), {
      completed: !currentState
    });
  };

  // 教師チェックの更新
  const handleTeacherCheck = async (date, homeworkId, status) => {
    const currentCheck = homeworkByDate[date]?.tasks[homeworkId]?.teacherCheck;
    await update(ref(database, `homework/${studentId}/${date}/tasks/${homeworkId}`), {
      teacherCheck: currentCheck === status ? null : status
    });
  };

  // 日付の追加
  const handleAddDate = async () => {
    if (!selectedDate) {
      alert('日付を選択してください。');
      return;
    }

    if (homeworkByDate[selectedDate]) {
      alert('この日付の宿題は既に存在します。');
      return;
    }

    const newDate = selectedDate; // 新しく追加する日付をキャプチャ
    await set(ref(database, `homework/${studentId}/${newDate}`), {
      date: newDate,
      tasks: {}
    });

    setSelectedDate('');
    setIsAddingDate(false);
    setAddingHomeworkDate(newDate); // 宿題追加フォームを自動的に開く
  };

  // 「C追加」ボタンをクリックした際の処理
  const handleCopyAddDate = async () => {
    if (!selectedDate) {
      alert('日付を選択してください。');
      return;
    }

    if (homeworkByDate[selectedDate]) {
      alert('この日付の宿題は既に存在します。');
      return;
    }

    const dates = Object.keys(homeworkByDate);
    if (dates.length === 0) {
      alert('コピーする宿題がありません。');
      return;
    }

    // 日付を降順にソートして最新の日付を取得
    const sortedDates = [...dates].sort((a, b) => new Date(b) - new Date(a));
    const lastDate = sortedDates[0];
    const lastTasks = homeworkByDate[lastDate]?.tasks || {};

    // 新しい日付を追加
    await set(ref(database, `homework/${studentId}/${selectedDate}`), {
      date: selectedDate,
      tasks: {}
    });

    // タスクをコピー
    const newTasksRef = ref(database, `homework/${studentId}/${selectedDate}/tasks`);
    const updates = {};
    const newTaskIds = []; // 新しく追加されたタスクのIDを保持

    Object.values(lastTasks).forEach((task) => {
      const newTaskRef = push(newTasksRef);
      updates[newTaskRef.key] = { ...task, id: newTaskRef.key };
      newTaskIds.push(newTaskRef.key);
    });

    if (Object.keys(updates).length > 0) {
      await update(newTasksRef, updates);
    }

    setSelectedDate('');
    setIsAddingDate(false);

    if (newTaskIds.length > 0) {
      // 最初のコピーされたタスクの編集画面を開く
      setEditingTaskId(newTaskIds[0]);
      // Firebaseから新しいタスクの詳細を取得して編集フィールドを設定
      const firstTask = lastTasks[Object.keys(lastTasks)[0]];
      setEditTaskSubject(firstTask.subject);
      setEditTaskContent(firstTask.content);
      setEditTaskDueDate(firstTask.dueDate || '');
      setEditTaskComment(firstTask.teacherComment || '');
    }

    alert('前回の宿題がコピーされました。編集画面が開きます。');
  };

  // 日付の編集
  const handleEditDate = async () => {
    if (!newDateValue) {
      setError('新しい日付を入力してください。');
      return;
    }

    if (homeworkByDate[newDateValue]) {
      setError('この日付は既に存在します。');
      return;
    }

    try {
      // 現在の編集対象の日付データを取得
      const currentData = homeworkByDate[editingDate];
      if (!currentData) {
        setError('編集対象の日付が見つかりません。');
        return;
      }

      // 新しい日付キーにデータをコピー
      await set(ref(database, `homework/${studentId}/${newDateValue}`), currentData);

      // 古い日付キーを削除
      await remove(ref(database, `homework/${studentId}/${editingDate}`));

      // 編集状態をリセット
      setEditingDate(null);
      setNewDateValue('');
      setError('');
    } catch (err) {
      console.error('日付の編集中にエラーが発生しました:', err);
      setError('日付の編集中にエラーが発生しました。再度お試しください。');
    }
  };

  // キャンセル編集
  const handleCancelEditDate = () => {
    setEditingDate(null);
    setNewDateValue('');
    setError('');
  };

  // タスクの編集開始
  const handleStartEditTask = (id, subject, content, dueDate, teacherComment) => {
    setEditingTaskId(id);
    setEditTaskSubject(subject);
    setEditTaskContent(content);
    setEditTaskDueDate(dueDate || '');
    setEditTaskComment(teacherComment || '');
    setTaskError('');
  };

  // タスク編集のキャンセル
  const handleCancelEditTask = () => {
    setEditingTaskId(null);
    setEditTaskSubject('');
    setEditTaskContent('');
    setEditTaskDueDate('');
    setEditTaskComment('');
    setTaskError('');
  };

  // タスク編集の保存
  const handleSaveEditTask = async (date, id) => {
    if (!editTaskSubject.trim() || !editTaskContent.trim()) {
      setTaskError('教科と内容を入力してください。');
      return;
    }

    try {
      await handleUpdateHomework(date, id, {
        subject: editTaskSubject,
        content: editTaskContent,
        dueDate: editTaskDueDate,
        teacherComment: editTaskComment,
      });
    } catch (err) {
      console.error('タスクの編集中にエラーが発生しました:', err);
      setTaskError('タスクの編集中にエラーが発生しました。再度お試しください。');
    }
  };

  const isTeacher = currentUser && (currentUser.role === 'teacher' || currentUser.isMaster);

  // 「日付の追加」ボタンを押したときに現在の日付をデフォルトに設定する
  const handleStartAddingDate = () => {
    const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式
    setSelectedDate(today);
    setIsAddingDate(true);
  };

  return (
    <div className="p-4 bg-yellow-50 rounded-lg shadow w-full">
      {!studentId ? (
        <div className="text-red-500">生徒を選択してください。</div>
      ) : (
        <div>
          {/* 生徒ビューへの注意書き */}
          {!isTeacher && (
            <div className="mb-2 p-2 bg-yellow-100 text-yellow-800 rounded">
              終わった宿題にはチェックマークを!
            </div>
          )}

          {/* 日付の追加ボタン */}
          {isTeacher && (
            <div className="mb-4">
              {!isAddingDate ? (
                <button
                  onClick={handleStartAddingDate} // 現在の日付を設定
                  className="w-full py-2 bg-purple-100 text-purple-700 rounded-lg hover:bg-purple-200 flex items-center justify-center gap-2"
                >
                  <PlusCircle size={20} />
                  日付を追加
                </button>
              ) : (
                <div className="bg-white p-3 rounded-lg border border-purple-200 w-full">
                  <input
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    className="w-full p-2 border border-purple-300 rounded mb-2"
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={handleAddDate}
                      className="px-4 py-1 bg-purple-300 text-purple-800 rounded hover:bg-purple-400"
                    >
                      追加
                    </button>
                    <button
                      onClick={handleCopyAddDate}
                      className="px-4 py-1 bg-blue-300 text-blue-800 rounded hover:bg-blue-400"
                    >
                      C追加
                    </button>
                    <button
                      onClick={() => {
                        setIsAddingDate(false);
                        setSelectedDate('');
                      }}
                      className="px-4 py-1 bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                    >
                      キャンセル
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* 日付ごとの宿題カード */}
          <div className="space-y-2">
            {Object.entries(homeworkByDate)
              .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA)) // 修正済み
              .map(([date, homework]) => (
                <div key={date} className="bg-white rounded-lg shadow border border-purple-200 w-full">
                  {/* 日付ヘッダー */}
                  <div className="bg-purple-100 px-4 py-2 flex justify-between items-center rounded-t-lg">
                    <h3 className="text-lg font-semibold text-purple-800">
                      {formatDate(date)}の宿題
                    </h3>
                    {isTeacher && (
                      <div className="flex items-center space-x-3">
                        {editingDate === date ? (
                          <div className="flex items-center space-x-2">
                            <input
                              type="date"
                              value={newDateValue}
                              onChange={(e) => setNewDateValue(e.target.value)}
                              className="p-1 border border-purple-300 rounded"
                            />
                            <button
                              onClick={handleEditDate}
                              className="px-2 py-1 bg-green-300 text-green-800 rounded hover:bg-green-400"
                            >
                              保存
                            </button>
                            <button
                              onClick={handleCancelEditDate}
                              className="px-2 py-1 bg-red-300 text-red-800 rounded hover:bg-red-400"
                            >
                              キャンセル
                            </button>
                          </div>
                        ) : (
                          <>
                            <button
                              onClick={() => {
                                setEditingDate(date);
                                setNewDateValue(date);
                              }}
                              className="text-purple-700 hover:text-purple-900"
                              title="日付を編集"
                            >
                              <Edit2 size={18} />
                            </button>
                            {/* 削除ボタンの追加 */}
                            <button
                              onClick={() => handleDeleteDate(date)}
                              className="text-pink-600 hover:text-pink-800"
                              title="この日付の宿題を一括削除"
                            >
                              <Trash2 size={18} />
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  {/* 日付編集時のエラーメッセージ */}
                  {error && editingDate === date && (
                    <div className="mb-2 p-2 bg-red-100 text-red-800 rounded">
                      {error}
                    </div>
                  )}

                  {/* 宿題リスト */}
                  <div className="p-3 space-y-3">
                    {isTeacher && Object.keys(homework.tasks || {}).length === 0 && (
                      <div className="text-gray-500">この日付には宿題がありません。</div>
                    )}

                    {Object.entries(homework.tasks || {}).map(([id, task]) => (
                      <div key={id} className="border-b border-purple-100 pb-3">
                        {/* 1行目: 教科名、期限、アイコン、チェックボタン */}
                        <div className="flex items-center justify-between w-full">
                          {/* 左側: 教科名と期限 */}
                          <div className="flex items-center space-x-2">
                            <span className="font-semibold text-purple-800">{task.subject}</span>
                            {task.dueDate && (
                              <span className="text-sm text-purple-600 flex items-center">
                                <Calendar size={14} className="mr-1" />
                                {formatDueDate(task.dueDate)}
                              </span>
                            )}
                          </div>

                          {/* 右側: アイコンとチェックボタン */}
                          <div className="flex items-center space-x-2">
                            {/* 生徒用チェックボタン */}
                            {!isTeacher && (
                              <button
                                onClick={() => handleToggleComplete(date, id, task.completed)}
                                className="text-purple-500 hover:text-purple-700"
                              >
                                {task.completed ? <CheckSquare size={24} /> : <Square size={24} />}
                              </button>
                            )}

                            {/* 教師用の編集・削除・評価ボタン */}
                            {isTeacher && (
                              <>
                                {/* タスク編集ボタン */}
                                <button
                                  onClick={() => handleStartEditTask(id, task.subject, task.content, task.dueDate, task.teacherComment)}
                                  className="text-blue-500 hover:text-blue-700"
                                  title="タスクを編集"
                                >
                                  <Edit2 size={18} />
                                </button>

                                {/* 教師用の削除ボタン */}
                                <button
                                  onClick={() => handleDeleteHomework(date, id)}
                                  className="text-pink-500 hover:text-pink-700"
                                >
                                  <X size={16} />
                                </button>

                                {/* 教師用の評価ボタン */}
                                <div className="flex space-x-1">
                                  <button
                                    onClick={() => handleTeacherCheck(date, id, 'maru')}
                                    className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
                                      task.teacherCheck === 'maru'
                                        ? 'bg-yellow-300 text-yellow-800'
                                        : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                                    }`}
                                  >
                                    ○
                                  </button>
                                  <button
                                    onClick={() => handleTeacherCheck(date, id, 'triangle')}
                                    className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
                                      task.teacherCheck === 'triangle'
                                        ? 'bg-purple-300 text-purple-800'
                                        : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                                    }`}
                                  >
                                    △
                                  </button>
                                  <button
                                    onClick={() => handleTeacherCheck(date, id, 'batsu')}
                                    className={`w-6 h-6 rounded-full flex items-center justify-center text-sm ${
                                      task.teacherCheck === 'batsu'
                                        ? 'bg-pink-300 text-pink-800'
                                        : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                                    }`}
                                  >
                                    ×
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {/* タスク内容と teacherComment の表示 */}
                        <div className="mt-2 pl-8">
                          {editingTaskId === id ? (
                            <div className="space-y-1 w-full">
                              {/* タスク編集用入力フィールド */}
                              {/* 教科をドロップダウンに変更 */}
                              <select
                                value={editTaskSubject}
                                onChange={(e) => setEditTaskSubject(e.target.value)}
                                className="w-full p-2 border border-purple-300 rounded"
                              >
                                <option value="">教科を選択</option>
                                {subjects.map((subject) => (
                                  <option key={subject} value={subject}>
                                    {subject}
                                  </option>
                                ))}
                              </select>
                              <textarea
                                value={editTaskContent}
                                onChange={(e) => setEditTaskContent(e.target.value)}
                                className="w-full p-2 border border-purple-300 rounded"
                                placeholder="内容"
                                rows={2}
                              />
                              <input
                                type="date"
                                value={editTaskDueDate}
                                onChange={(e) => setEditTaskDueDate(e.target.value)}
                                className="w-full p-2 border border-purple-300 rounded"
                                placeholder="期限"
                              />
                              {/* 教師コメント入力フィールドの追加 */}
                              <textarea
                                value={editTaskComment}
                                onChange={(e) => setEditTaskComment(e.target.value)}
                                className="w-full p-2 border border-green-300 rounded"
                                placeholder="教師からのコメント（生徒に表示されます）"
                                rows={2}
                              />
                              {/* タスク編集時のエラーメッセージ */}
                              {taskError && (
                                <div className="mb-1 p-2 bg-red-100 text-red-800 rounded">
                                  {taskError}
                                </div>
                              )}
                              <div className="flex space-x-2">
                                <button
                                  onClick={() => handleSaveEditTask(date, id)}
                                  className="px-3 py-1 bg-green-300 text-green-800 rounded hover:bg-green-400"
                                >
                                  保存
                                </button>
                                <button
                                  onClick={handleCancelEditTask}
                                  className="px-3 py-1 bg-red-300 text-red-800 rounded hover:bg-red-400"
                                >
                                  キャンセル
                                </button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="flex items-start justify-between w-full">
                                {/* テキストを包むラッパーを追加 */}
                                <div className="flex-1 min-w-0">
                                  <p
                                    className={`${
                                      task.completed ? 'line-through text-gray-500' : 'text-purple-700'
                                    } break-words whitespace-pre-line`}
                                  >
                                    {task.content}
                                  </p>
                                </div>
                                {/* 生徒ビューでの teacherCheck 表示 */}
                                {!isTeacher && task.teacherCheck && (
                                  <div className="ml-4 flex-shrink-0 mt-2 md:mt-0">
                                    {task.teacherCheck === 'maru' && (
                                      <span className="text-green-600 font-bold text-2xl">○</span>
                                    )}
                                    {task.teacherCheck === 'triangle' && (
                                      <span className="text-yellow-600 font-bold text-2xl">△</span>
                                    )}
                                    {task.teacherCheck === 'batsu' && (
                                      <span className="text-red-600 font-bold text-2xl">×</span>
                                    )}
                                  </div>
                                )}
                              </div>
                              {/* 教師コメントの表示（教師・生徒両方） */}
                              {task.teacherComment && (
                                <div className="mt-2 pl-4 border-l-4 border-green-300">
                                  <p className="text-green-700 whitespace-pre-line break-words">
                                    {task.teacherComment}
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* 宿題追加フォーム */}
                  {isTeacher && (
                    <div className="w-full">
                      {addingHomeworkDate !== date ? (
                        <button
                          onClick={() => setAddingHomeworkDate(date)}
                          className="w-full py-2 text-purple-700 rounded-lg hover:bg-purple-100 flex items-center justify-center gap-2"
                        >
                          <PlusCircle size={20} />
                          宿題を追加
                        </button>
                      ) : (
                        <div className="border-t border-purple-100 pt-2 pb-2 pl-2 pr-2 w-full">
                          <div className="space-y-2">
                            {/* 教科をドロップダウンに変更 */}
                            <select
                              value={newHomework.subject}
                              onChange={(e) =>
                                setNewHomework({
                                  ...newHomework,
                                  subject: e.target.value,
                                })
                              }
                              className="w-full p-2 border border-purple-300 rounded"
                            >
                              <option value="">教科を選択</option>
                              {subjects.map((subject) => (
                                <option key={subject} value={subject}>
                                  {subject}
                                </option>
                              ))}
                            </select>
                            <textarea
                              value={newHomework.content}
                              onChange={(e) =>
                                setNewHomework({
                                  ...newHomework,
                                  content: e.target.value,
                                })
                              }
                              className="w-full p-2 border border-purple-300 rounded"
                              placeholder="内容"
                              rows={2}
                            />
                            <input
                              type="date"
                              value={newHomework.dueDate}
                              onChange={(e) =>
                                setNewHomework({
                                  ...newHomework,
                                  dueDate: e.target.value,
                                })
                              }
                              className="w-full p-2 border border-purple-300 rounded"
                              placeholder="期限"
                            />
                            {/* 教師コメント入力フィールドの追加 */}
                            <textarea
                              value={newHomework.teacherComment}
                              onChange={(e) =>
                                setNewHomework({
                                  ...newHomework,
                                  teacherComment: e.target.value,
                                })
                              }
                              className="w-full p-2 border border-green-300 rounded"
                              placeholder="教師からのコメント（生徒に表示されます）"
                              rows={2}
                            />
                            {/* 余白を追加 */}
                            <div className="flex justify-end space-x-2 mb-6">
                              <button
                                onClick={() => handleAddHomework(date)}
                                className="px-4 py-1 bg-purple-300 text-purple-800 rounded hover:bg-purple-400"
                              >
                                追加
                              </button>
                              <button
                                onClick={() => {
                                  setAddingHomeworkDate(null);
                                  setNewHomework({ subject: '', content: '', dueDate: '', teacherComment: '' });
                                }}
                                className="px-4 py-1 bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
                              >
                                キャンセル
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}

      {/* ConfirmDialogのレンダリング */}
      {isConfirmOpen && (
        <ConfirmDialog
          title={confirmTitle}
          message={confirmMessage}
          onConfirm={onConfirmAction}
          onCancel={() => setIsConfirmOpen(false)}
          confirmButtonText="削除"
          cancelButtonText="キャンセル"
        />
      )}
    </div>
  );
};

HomeworkByDate.propTypes = {
  selectedStudent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  currentUser: PropTypes.shape({
    role: PropTypes.oneOf(['student', 'teacher']).isRequired,
    id: PropTypes.string.isRequired,
    isMaster: PropTypes.bool,
  }),
};

export default HomeworkByDate;
