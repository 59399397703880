// StudentList.js

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from './ConfirmDialog';
import useUnreadMessages from './hooks/useUnreadMessages';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { UserPlus, MessageSquare, Edit3 } from 'lucide-react';

// 学年リスト
const GRADES = [
  { id: 'shougaku1', name: '小1' },
  { id: 'shougaku2', name: '小2' },
  { id: 'shougaku3', name: '小3' },
  { id: 'shougaku4', name: '小4' },
  { id: 'shougaku5', name: '小5' },
  { id: 'shougaku6', name: '小6' },
  { id: 'chuugaku1', name: '中1' },
  { id: 'chuugaku2', name: '中2' },
  { id: 'chuugaku3', name: '中3' },
  { id: 'koukou1', name: '高1' },
  { id: 'koukou2', name: '高2' },
  { id: 'koukou3', name: '高3' },
];

// 学年の表示形式を変換する関数
const formatGrade = (gradeId) => {
  const grade = GRADES.find((g) => g.id === gradeId);
  return grade ? grade.name : gradeId;
};

/**
 * 生徒リストコンポーネント
 *
 * @param {Object} props - コンポーネントのプロパティ
 */
const StudentList = ({
  droppableId,
  students,
  selectedStudent,
  onSelectStudent,
  onAddStudent,
  onUpdateStudent,
  onDeleteStudent,
  currentUser,
  onOpenMessaging,
  onClose, // モバイル用のサイドバーを閉じるハンドラー
}) => {
  const [newStudentData, setNewStudentData] = useState({
    name: '',
    username: '',
    password: '',
    grade: '',
    order: 0,
  });
  const [isAddingStudent, setIsAddingStudent] = useState(false);
  const [editingStudentId, setEditingStudentId] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState('all');
  const { getUnreadCount } = useUnreadMessages(currentUser);

  // 生徒リストを order プロパティでソート
  const sortedStudents = useMemo(() => {
    return [...students].sort((a, b) => (a.order || 0) - (b.order || 0));
  }, [students]);

  // 学年でフィルタリング
  const filteredStudents = useMemo(() => {
    return selectedGrade === 'all'
      ? sortedStudents
      : sortedStudents.filter((student) => student.grade === selectedGrade);
  }, [sortedStudents, selectedGrade]);

  return (
    <div className="p-4 flex flex-col h-full">
      {/* 学年フィルタ */}
      <div className="mb-4">
        <select
          value={selectedGrade}
          onChange={(e) => setSelectedGrade(e.target.value)}
          className="w-full border rounded p-2 bg-yellow-100 text-yellow-700"
          aria-label="学年でフィルタリング"
        >
          <option value="all">すべての学年</option>
          {GRADES.map((grade) => (
            <option key={grade.id} value={grade.id}>
              {grade.name}
            </option>
          ))}
        </select>
      </div>

      {/* 生徒追加ボタンまたはフォーム */}
      <div className="mb-4">
        {!isAddingStudent ? (
          <button
            onClick={() => setIsAddingStudent(true)}
            className="w-full bg-yellow-300 text-yellow-800 px-4 py-2 rounded hover:bg-yellow-400 flex items-center justify-center gap-2 transition-colors duration-200"
            aria-label="生徒追加フォームを開く"
          >
            <UserPlus size={20} />
            生徒を追加
          </button>
        ) : (
          <div className="mb-4">
            <h3 className="font-semibold text-lg mb-2">生徒の追加</h3>
            <input
              type="text"
              value={newStudentData.name}
              onChange={(e) =>
                setNewStudentData({ ...newStudentData, name: e.target.value })
              }
              placeholder="生徒名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="生徒名入力"
            />
            <input
              type="text"
              value={newStudentData.username}
              onChange={(e) =>
                setNewStudentData({ ...newStudentData, username: e.target.value })
              }
              placeholder="ユーザー名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="ユーザー名入力"
            />
            <input
              type="password"
              value={newStudentData.password}
              onChange={(e) =>
                setNewStudentData({ ...newStudentData, password: e.target.value })
              }
              placeholder="パスワード"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="パスワード入力"
            />
            <select
              value={newStudentData.grade}
              onChange={(e) =>
                setNewStudentData({ ...newStudentData, grade: e.target.value })
              }
              className="w-full border rounded p-2 mb-4 bg-purple-100 text-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="学年を選択"
            >
              <option value="">学年を選択</option>
              {GRADES.map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.name}
                </option>
              ))}
            </select>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  if (
                    !newStudentData.name ||
                    !newStudentData.username ||
                    !newStudentData.password ||
                    !newStudentData.grade
                  ) {
                    alert('すべての項目を入力してください');
                    return;
                  }
                  const order = Math.max(
                    ...students.map((s) => s.order || 0),
                    -1
                  ) + 1;
                  onAddStudent({ ...newStudentData, order });
                  setNewStudentData({
                    name: '',
                    username: '',
                    password: '',
                    grade: '',
                    order: 0,
                  });
                  setIsAddingStudent(false);
                }}
                className="bg-purple-300 text-purple-800 px-4 py-2 rounded hover:bg-purple-400"
                aria-label="生徒追加保存ボタン"
              >
                追加
              </button>
              <button
                onClick={() => {
                  setNewStudentData({
                    name: '',
                    username: '',
                    password: '',
                    grade: '',
                    order: 0,
                  });
                  setIsAddingStudent(false);
                }}
                className="bg-purple-100 text-purple-700 px-4 py-2 rounded hover:bg-purple-200"
                aria-label="生徒追加キャンセルボタン"
              >
                キャンセル
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 生徒リスト */}
      <Droppable droppableId={droppableId}>
        {(droppableProvided) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            className="flex-1 overflow-y-auto space-y-2 relative" // 'relative' を追加
          >
            {filteredStudents.map((student, index) => {
              const isEditing = editingStudentId === student.id;

              return (
                <Draggable
                  key={student.id}
                  draggableId={student.id}
                  index={index}
                  isDragDisabled={!currentUser.isMaster}
                >
                  {(draggableProvided, snapshot) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      
                      
                      
                      
                      
                      className={`p-3 rounded-lg transition-colors duration-200 ${
                        selectedStudent?.id === student.id
                          ? 'bg-yellow-100'
                          : 'hover:bg-purple-100'
                      } ${snapshot.isDragging ? 'opacity-70 shadow-lg z-50' : ''}`} // 'z-50' を追加
                    >
                      {isEditing ? (
                        // 編集フォーム
                        <div className="w-full">
                          <input
                            type="text"
                            value={newStudentData.name}
                            onChange={(e) =>
                              setNewStudentData({
                                ...newStudentData,
                                name: e.target.value,
                              })
                            }
                            placeholder="生徒名"
                            className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
                            aria-label="生徒名入力"
                          />
                          <input
                            type="text"
                            value={newStudentData.username}
                            onChange={(e) =>
                              setNewStudentData({
                                ...newStudentData,
                                username: e.target.value,
                              })
                            }
                            placeholder="ユーザー名"
                            className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
                            aria-label="ユーザー名入力"
                          />
                          <input
                            type="password"
                            value={newStudentData.password}
                            onChange={(e) =>
                              setNewStudentData({
                                ...newStudentData,
                                password: e.target.value,
                              })
                            }
                            placeholder="パスワード"
                            className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
                            aria-label="パスワード入力"
                          />
                          <select
                            value={newStudentData.grade}
                            onChange={(e) =>
                              setNewStudentData({
                                ...newStudentData,
                                grade: e.target.value,
                              })
                            }
                            className="w-full border rounded p-2 mb-4 bg-purple-100 text-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-300"
                            aria-label="学年を選択"
                          >
                            <option value="">学年を選択</option>
                            {GRADES.map((grade) => (
                              <option key={grade.id} value={grade.id}>
                                {grade.name}
                              </option>
                            ))}
                          </select>
                          <div className="flex justify-end space-x-2">
                            <button
                              onClick={() => {
                                if (
                                  !newStudentData.name ||
                                  !newStudentData.username ||
                                  !newStudentData.password ||
                                  !newStudentData.grade
                                ) {
                                  alert('すべての項目を入力してください');
                                  return;
                                }
                                onUpdateStudent({ ...student, ...newStudentData });
                                setEditingStudentId(null);
                                setNewStudentData({
                                  name: '',
                                  username: '',
                                  password: '',
                                  grade: '',
                                  order: student.order || 0,
                                });
                              }}
                              className="bg-purple-300 text-purple-800 px-4 py-2 rounded hover:bg-purple-400"
                              aria-label="生徒情報保存ボタン"
                            >
                              保存
                            </button>
                            <button
                              onClick={() => {
                                setEditingStudentId(null);
                                setNewStudentData({
                                  name: '',
                                  username: '',
                                  password: '',
                                  grade: '',
                                  order: student.order || 0,
                                });
                              }}
                              className="bg-purple-100 text-purple-700 px-4 py-2 rounded hover:bg-purple-200"
                              aria-label="生徒情報編集キャンセルボタン"
                            >
                              キャンセル
                            </button>
                            <button
                              onClick={() => {
                                setConfirmDialog({
                                  title: '生徒の削除',
                                  message: `${student.name}を削除してもよろしいですか？`,
                                  onConfirm: () => {
                                    onDeleteStudent(student.id);
                                    setEditingStudentId(null);
                                    setNewStudentData({
                                      name: '',
                                      username: '',
                                      password: '',
                                      grade: '',
                                      order: student.order || 0,
                                    });
                                    setConfirmDialog(null);
                                  },
                                  onCancel: () => setConfirmDialog(null),
                                });
                              }}
                              className="bg-pink-300 text-pink-800 px-4 py-2 rounded hover:bg-pink-400"
                              aria-label="生徒削除ボタン"
                            >
                              削除
                            </button>
                          </div>
                        </div>
                      ) : (
                        // 通常の生徒表示
                        <div
                          className="flex items-center justify-between cursor-pointer"
                          onClick={() => onSelectStudent(student)}
                          aria-label={`生徒選択: ${student.name}`}
                        >
                          <div>
                            <div className="font-medium text-purple-700 hover:text-purple-800">
                              {student.name}（{formatGrade(student.grade)}）
                            </div>
                            <div className="text-sm text-purple-600">
                              ユーザー名: {student.username}
                            </div>
                          </div>
                          {currentUser.isMaster && (
                            <div className="flex items-center space-x-2 ml-4">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onOpenMessaging(student);
                                }}
                                className="relative text-purple-500 hover:text-yellow-500"
                                aria-label={`メッセージを開く: ${student.name}`}
                              >
                                <MessageSquare size={20} />
                                {getUnreadCount(
                                  currentUser.id < student.id
                                    ? `${currentUser.id}_${student.id}`
                                    : `${student.id}_${currentUser.id}`
                                ) > 0 && (
                                  <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-yellow-100 bg-yellow-600 rounded-full">
                                    {getUnreadCount(
                                      currentUser.id < student.id
                                        ? `${currentUser.id}_${student.id}`
                                        : `${student.id}_${currentUser.id}`
                                    )}
                                  </span>
                                )}
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingStudentId(student.id);
                                  setNewStudentData({
                                    name: student.name,
                                    username: student.username,
                                    password: student.password,
                                    grade: student.grade,
                                    order: student.order || 0,
                                  });
                                }}
                                className="text-purple-500 hover:text-purple-700"
                                aria-label={`生徒編集: ${student.name}`}
                              >
                                <Edit3 size={20} />
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>

      {/* サイドバーを閉じるボタン（モバイル用） */}
      {onClose && (
        <button
          onClick={onClose}
          className="mt-4 w-full py-2 bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
          aria-label="サイドバーを閉じる"
        >
          閉じる
        </button>
      )}

      {/* 確認ダイアログ */}
      {confirmDialog && (
        <ConfirmDialog
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
    </div>
  );
};

StudentList.propTypes = {
  droppableId: PropTypes.string.isRequired,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      grade: PropTypes.string.isRequired,
      order: PropTypes.number,
    })
  ).isRequired,
  selectedStudent: PropTypes.object,
  onSelectStudent: PropTypes.func.isRequired,
  onAddStudent: PropTypes.func.isRequired,
  onUpdateStudent: PropTypes.func.isRequired,
  onDeleteStudent: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired, // 'teacher' または 'student'
    isMaster: PropTypes.bool, // マスターティーチャーかどうか
    // 他のプロパティも必要に応じて追加
  }).isRequired,
  onOpenMessaging: PropTypes.func.isRequired,
  onClose: PropTypes.func, // モバイル用のサイドバーを閉じるハンドラーはオプショナル
};

export default StudentList;
