// TeacherList.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ConfirmDialog from './ConfirmDialog';
import useUnreadMessages from './hooks/useUnreadMessages';
import { UserPlus, MessageSquare, Edit3 } from 'lucide-react';

/**
 * 教師リストコンポーネント（マスター用）
 *
 * @param {Object} props - コンポーネントのプロパティ
 * @param {Array} props.teachers - 教師のリスト
 * @param {Object} props.selectedTeacher - 選択されている教師
 * @param {Function} props.onSelectTeacher - 教師選択時のハンドラー
 * @param {Function} props.onAddTeacher - 教師追加時のハンドラー
 * @param {Function} props.onUpdateTeacher - 教師更新時のハンドラー
 * @param {Function} props.onDeleteTeacher - 教師削除時のハンドラー
 * @param {Function} props.onClose - サイドバーを閉じるハンドラー
 * @param {Object} props.currentUser - 現在のユーザー情報
 * @param {Function} props.onOpenMessaging - メッセージングを開くハンドラー
 */
const TeacherList = ({
  teachers,
  selectedTeacher,
  onSelectTeacher,
  onAddTeacher,
  onUpdateTeacher,
  onDeleteTeacher,
  onClose,
  currentUser,
  onOpenMessaging,
}) => {
  const [newTeacherData, setNewTeacherData] = useState({
    name: '',
    username: '',
    password: '',
    isMaster: false,
  });
  const [isAddingTeacher, setIsAddingTeacher] = useState(false);
  const [editingTeacherId, setEditingTeacherId] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);

  // デバッグ用ログ
  useEffect(() => {
    console.log('TeacherList - currentUser:', currentUser);
    console.log('TeacherList - teachers:', teachers);
  }, [currentUser, teachers]);

  // フックの呼び出しをトップレベルに移動
  const { getUnreadCount } = useUnreadMessages(currentUser);

  return (
    <div className="p-4 flex flex-col h-full">
      {/* 教師の追加トグルボタン */}
      <div className="mb-4">
        {!isAddingTeacher ? (
          <button
            onClick={() => setIsAddingTeacher(true)}
            className="w-full bg-yellow-300 text-yellow-800 px-4 py-2 rounded hover:bg-yellow-400 flex items-center justify-center gap-2 transition-colors duration-200"
            aria-label="教師追加フォームを開く"
          >
            <UserPlus size={20} />
            教師を追加
          </button>
        ) : (
          <div className="mb-4">
            <h3 className="font-semibold text-lg mb-2">教師の追加</h3>
            <input
              type="text"
              value={newTeacherData.name}
              onChange={(e) =>
                setNewTeacherData({
                  ...newTeacherData,
                  name: e.target.value,
                })
              }
              placeholder="教師名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="教師名入力"
            />
            <input
              type="text"
              value={newTeacherData.username}
              onChange={(e) =>
                setNewTeacherData({
                  ...newTeacherData,
                  username: e.target.value,
                })
              }
              placeholder="ユーザー名"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="ユーザー名入力"
            />
            <input
              type="password"
              value={newTeacherData.password}
              onChange={(e) =>
                setNewTeacherData({
                  ...newTeacherData,
                  password: e.target.value,
                })
              }
              placeholder="パスワード"
              className="w-full p-2 border rounded mb-2 focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="パスワード入力"
            />
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                checked={newTeacherData.isMaster}
                onChange={(e) =>
                  setNewTeacherData({
                    ...newTeacherData,
                    isMaster: e.target.checked,
                  })
                }
                id="isMaster"
                className="mr-2"
                aria-label="マスター権限チェックボックス"
              />
              <label htmlFor="isMaster" className="text-purple-700">
                マスター権限
              </label>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  // 入力の検証（簡易）
                  if (
                    !newTeacherData.name ||
                    !newTeacherData.username ||
                    !newTeacherData.password
                  ) {
                    alert('すべての項目を入力してください');
                    return;
                  }
                  onAddTeacher(newTeacherData);
                  setNewTeacherData({
                    name: '',
                    username: '',
                    password: '',
                    isMaster: false,
                  });
                  setIsAddingTeacher(false);
                }}
                className="bg-purple-300 text-purple-800 px-4 py-2 rounded hover:bg-purple-400"
                aria-label="教師追加保存ボタン"
              >
                追加
              </button>
              <button
                onClick={() => {
                  setNewTeacherData({
                    name: '',
                    username: '',
                    password: '',
                    isMaster: false,
                  });
                  setIsAddingTeacher(false);
                }}
                className="bg-purple-100 text-purple-700 px-4 py-2 rounded hover:bg-purple-200"
                aria-label="教師追加キャンセルボタン"
              >
                キャンセル
              </button>
            </div>
          </div>
        )}
      </div>

      {/* 教師一覧の表示部分 */}
      <div className="flex-1 overflow-y-auto space-y-2">
        {teachers.map((teacher) => {
          // teacher が未定義の場合はスキップ
          if (!teacher || !teacher.id) {
            return null;
          }

          // 編集状態の確認
          const isEditing = editingTeacherId === teacher.id;

          // 未読メッセージ数の取得
          const conversationId =
            currentUser.id < teacher.id
              ? `${currentUser.id}_${teacher.id}`
              : `${teacher.id}_${currentUser.id}`;
          const teacherUnreadCount = getUnreadCount(conversationId);

          return (
            <div
              key={teacher.id}
              className={`p-3 rounded-lg transition-colors duration-200 ${
                selectedTeacher?.id === teacher.id
                  ? 'bg-yellow-100'
                  : 'hover:bg-purple-100'
              } flex items-center justify-between`}
              aria-label={`教師: ${teacher.name}`}
            >
              {isEditing ? (
                <div className="w-full">
                  <input
                    type="text"
                    value={newTeacherData.name}
                    onChange={(e) =>
                      setNewTeacherData({
                        ...newTeacherData,
                        name: e.target.value,
                      })
                    }
                    placeholder="教師名"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="教師名編集入力"
                  />
                  <input
                    type="text"
                    value={newTeacherData.username}
                    onChange={(e) =>
                      setNewTeacherData({
                        ...newTeacherData,
                        username: e.target.value,
                      })
                    }
                    placeholder="ユーザー名"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="ユーザー名編集入力"
                  />
                  <input
                    type="password"
                    value={newTeacherData.password}
                    onChange={(e) =>
                      setNewTeacherData({
                        ...newTeacherData,
                        password: e.target.value,
                      })
                    }
                    placeholder="パスワード"
                    className="w-full p-1.5 text-sm border rounded mb-1.5 focus:outline-none focus:ring-1 focus:ring-purple-300"
                    aria-label="パスワード編集入力"
                  />
                  <div className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={newTeacherData.isMaster}
                      onChange={(e) =>
                        setNewTeacherData({
                          ...newTeacherData,
                          isMaster: e.target.checked,
                        })
                      }
                      id={`edit-isMaster-${teacher.id}`}
                      className="mr-2"
                      aria-label="マスター権限編集チェックボックス"
                    />
                    <label
                      htmlFor={`edit-isMaster-${teacher.id}`}
                      className="text-purple-700"
                    >
                      マスター権限
                    </label>
                  </div>
                  <div className="flex justify-end space-x-1">
                    <button
                      onClick={() => {
                        if (
                          !newTeacherData.name ||
                          !newTeacherData.username ||
                          !newTeacherData.password
                        ) {
                          alert('すべての項目を入力してください');
                          return;
                        }
                        onUpdateTeacher({ ...teacher, ...newTeacherData });
                        setEditingTeacherId(null);
                        setNewTeacherData({
                          name: '',
                          username: '',
                          password: '',
                          isMaster: false,
                        });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-yellow-300 text-yellow-800 px-2 py-1.5 rounded hover:bg-yellow-400"
                      aria-label="教師情報保存ボタン"
                    >
                      保存
                    </button>
                    <button
                      onClick={() => {
                        setEditingTeacherId(null);
                        setNewTeacherData({
                          name: '',
                          username: '',
                          password: '',
                          isMaster: false,
                        });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-purple-100 text-purple-700 px-2 py-1.5 rounded hover:bg-purple-200"
                      aria-label="教師情報編集キャンセルボタン"
                    >
                      キャンセル
                    </button>
                    <button
                      onClick={() => {
                        setConfirmDialog({
                          title: '教師の削除',
                          message: `${teacher.name}を削除してもよろしいですか？`,
                          onConfirm: () => {
                            onDeleteTeacher(teacher.id);
                            setEditingTeacherId(null);
                            setNewTeacherData({
                              name: '',
                              username: '',
                              password: '',
                              isMaster: false,
                            });
                            setConfirmDialog(null);
                          },
                          onCancel: () => setConfirmDialog(null),
                        });
                      }}
                      className="min-w-[60px] text-sm whitespace-nowrap bg-pink-300 text-pink-800 px-2 py-1.5 rounded hover:bg-pink-400"
                      aria-label="教師削除ボタン"
                    >
                      削除
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="flex-1 cursor-pointer"
                    onClick={() => onSelectTeacher(teacher)}
                    aria-label={`教師選択: ${teacher.name}`}
                  >
                    <div className="font-medium text-purple-700 hover:text-purple-800">
                      {teacher.name} {teacher.isMaster && '(マスター)'}
                    </div>
                    <div className="text-sm text-purple-600">
                      ユーザー名: {teacher.username}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    {/* 編集ボタン */}
                    {(currentUser.isMaster || currentUser.id === teacher.id) && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditingTeacherId(teacher.id);
                          setNewTeacherData({
                            name: teacher.name,
                            username: teacher.username,
                            password: teacher.password,
                            isMaster: teacher.isMaster,
                          });
                        }}
                        className="text-purple-500 hover:text-purple-700 bg-transparent hover:bg-purple-200 p-2 rounded"
                        aria-label={`編集 ${teacher.name}`}
                      >
                        <Edit3 size={20} />
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>

      {/* モバイル表示の場合の閉じるボタン */}
      {onClose && (
        <button
          onClick={onClose}
          className="mt-4 w-full py-2 bg-purple-100 text-purple-700 rounded hover:bg-purple-200"
          aria-label="サイドバーを閉じる"
        >
          閉じる
        </button>
      )}

      {/* 確認ダイアログのレンダリング */}
      {confirmDialog && (
        <ConfirmDialog
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
    </div>
  );
};

TeacherList.propTypes = {
  teachers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      isMaster: PropTypes.bool.isRequired,
    })
  ).isRequired,
  selectedTeacher: PropTypes.object,
  onSelectTeacher: PropTypes.func.isRequired,
  onAddTeacher: PropTypes.func.isRequired,
  onUpdateTeacher: PropTypes.func.isRequired,
  onDeleteTeacher: PropTypes.func.isRequired,
  onClose: PropTypes.func, // サイドバー閉じる機能用
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isMaster: PropTypes.bool.isRequired,
    // 他のプロパティも必要に応じて追加
  }).isRequired,
  onOpenMessaging: PropTypes.func.isRequired, // このプロパティは不要になるかもしれません
};

export default TeacherList;
