// SubjectTaskBoard.js

import React, { useState, useEffect, useMemo } from 'react';
import ConfirmDialog from './ConfirmDialog';
import useUnreadMessages from './hooks/useUnreadMessages';
import { useUnreadNotesCount } from './hooks/useUnreadNotesCount'; // 名前付きインポート
import { saveAs } from 'file-saver';

import TeacherNotes from './TeacherNotes';
import * as XLSX from 'xlsx';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'; // Droppable と Draggable を正しくインポート

import {
  PlusCircle,
  X,
  LogOut,
  CheckSquare,
  Square,
  Calendar,
  Edit2,
  UserPlus,
  Menu,
  Edit,
  Upload,
  MessageSquare,
  Edit3,
  FileText,
  Download
} from 'lucide-react';

import { database } from './config/firebase';

import {
  ref,
  onValue,
  push,
  set,
  remove,
  get,
  update
} from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import Linkify from 'linkify-react';
import Login from './Login';
import Navbar from './Navbar';
import BulkTaskImport from './BulkTaskImport';
import MessagingModal from './MessagingModal';
import TeacherNotesModal from './TeacherNotesModal';
import IndividualAssignments from './IndividualAssignments';
import SchoolTaskSummary from './SchoolTaskSummary';
import QuizSection from './QuizSection';
import TeacherList from './TeacherList';
import StudentList from './StudentList';
import TaskItem from './TaskItem';
import EditTaskForm from './EditTaskForm';
import PropTypes from 'prop-types';
import Sidebar from './Sidebar';
import QuizManagement from './QuizManagement';

// フィルターの種類を定義
const FILTERS = [
  { id: 'all', name: '全て表示' },
  { id: 'dueAndNotConfirmed', name: '宿題期限あり＆未確認' },
  { id: 'noTestDate', name: '確認テスト日時未設定' },
  { id: 'failedConfirmation', name: '最終確認が不合格' },
];

// 定数: 科目リスト
const SUBJECTS = [
  { id: 'english', name: '英語' },
  { id: 'math', name: '数学' },
  { id: 'japanese', name: '国語' },
  { id: 'science', name: '理科' },
  { id: 'social', name: '社会' }
];

// 学年リスト
const GRADES = [
  { id: 'shougaku1', name: '小1' },
  { id: 'shougaku2', name: '小2' },
  { id: 'shougaku3', name: '小3' },
  { id: 'shougaku4', name: '小4' },
  { id: 'shougaku5', name: '小5' },
  { id: 'shougaku6', name: '小6' },
  { id: 'chuugaku1', name: '中1' },
  { id: 'chuugaku2', name: '中2' },
  { id: 'chuugaku3', name: '中3' },
  { id: 'koukou1', name: '高1' },
  { id: 'koukou2', name: '高2' },
  { id: 'koukou3', name: '高3' }
];

// 科目名を取得する関数
const getSubjectName = (subjectId) => {
  const subject = SUBJECTS.find((s) => s.id === subjectId);
  return subject ? subject.name : subjectId;
};

// Alertコンポーネント
const Alert = ({ children, variant = 'default', className = '' }) => (
  <div className={`p-4 rounded-lg ${
    variant === 'destructive' ? 'bg-yellow-100 text-yellow-700' : 'bg-purple-100 text-purple-700'
  } ${className}`}>
    {children}
  </div>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'destructive']),
  className: PropTypes.string,
};

// AlertDescriptionコンポーネント
const AlertDescription = ({ children, className = '' }) => (
  <div className={`text-sm ${className}`}>
    {children}
  </div>
);

AlertDescription.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// 科目タブコンポーネント
const SubjectTabs = ({ subjects, selectedSubject, onSelectSubject }) => (
  <div className="mb-6">
    {/* 科目ボタンの表示（区切りなし） */}
    <div className="flex items-center space-x-2">
      {subjects.map(subject => (
        <button
          key={subject.id}
          onClick={() => onSelectSubject(subject)}
          className={`px-4 py-2 rounded-full font-medium transition-colors duration-200 ${
            selectedSubject.id === subject.id
              ? 'bg-purple-300 text-purple-800 shadow'
              : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
          }`}
          aria-label={`科目タブ: ${subject.name}`}
        >
          {subject.name}
        </button>
      ))}
    </div>
  </div>
);

SubjectTabs.propTypes = {
  subjects: PropTypes.array.isRequired,
  selectedSubject: PropTypes.object.isRequired,
  onSelectSubject: PropTypes.func.isRequired,
};

// タスクカードコンポーネント
const TaskCard = ({
  task,
  isTeacher,
  onDeleteTask,
  onUpdateTask,
  onCheckItem,
  onTeacherCheckItem,
  onPassFailItem,
  onUpdateItem,
  onDeleteItem,
  getStudentId, // 親から渡される関数
  selectedSubject, // 親から渡されるオブジェクト
  currentUser, // 追加: currentUser をプロパティとして受け取る
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0]);

  return (
    <div className="p-4 mb-6 bg-yellow-50 rounded-lg shadow">
      {isEditing ? (
        <EditTaskForm
          task={task}
          onSave={(updatedTask) => {
            onUpdateTask(updatedTask);
            setIsEditing(false);
          }}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <>
          {/* 課題のタイトルとドラッグハンドル */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              {isTeacher && (
                <span
                  {...task.dragHandleProps}
                  className="mr-2 cursor-grab text-purple-500 hover:text-purple-700"
                  aria-label="課題をドラッグ"
                >
                  <Menu size={22} />
                </span>
              )}
              <div className="font-semibold text-lg text-purple-700">
                {task.title}
              </div>
            </div>
            {isTeacher && (
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setIsEditing(true)}
                  className="text-purple-500 hover:text-purple-700"
                  aria-label="課題を編集"
                >
                  <Edit2 size={22} />
                </button>

                <button
                  onClick={() => {
                    setConfirmDialog({
                      title: '課題の削除',
                      message: 'この課題を削除してもよろしいですか？',
                      onConfirm: () => {
                        onDeleteTask();
                        setConfirmDialog(null);
                      },
                      onCancel: () => setConfirmDialog(null),
                    });
                  }}
                  className="text-pink-500 hover:text-pink-700"
                  aria-label="課題を削除"
                >
                  <X size={22} />
                </button>
              </div>
            )}
          </div>

          {/* フィルターの選択UI */}
          <div className="mb-4">
            <select
              value={selectedFilter.id}
              onChange={(e) => {
                const filter = FILTERS.find((f) => f.id === e.target.value);
                setSelectedFilter(filter);
              }}
              className="w-full p-2 border border-purple-300 rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
              aria-label="課題フィルター選択"
            >
              {FILTERS.map((filter) => (
                <option key={filter.id} value={filter.id}>
                  {filter.name}
                </option>
              ))}
            </select>
          </div>

          {/* 折りたたみボタン */}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="mb-4 text-purple-500 hover:text-purple-600 flex items-center gap-1 focus:outline-none transition-colors duration-200"
            aria-label={
              isCollapsed ? 'チェック項目を展開' : 'チェック項目を折りたたむ'
            }
            aria-expanded={!isCollapsed}
            aria-controls={`task-items-${task.id}`}
          >
            {isCollapsed ? 'チェック項目を表示' : 'チェック項目を非表示'}
          </button>

          {/* チェック項目の表示 */}
          {!isCollapsed && (
            <Droppable droppableId={`items-${task.id}`} type={`taskItems-${task.id}`}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="space-y-4"
                >
                  {Object.entries(task.items || {})
                    .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
                    .map(([itemId, item], index) => (
                      <Draggable
                        key={itemId}
                        draggableId={`${task.id}-${itemId}`}
                        index={index}
                        isDragDisabled={!currentUser.isMaster}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              opacity: snapshot.isDragging ? 0.8 : 1
                            }}
                          >
                            <TaskItem
                              item={{ ...item, id: itemId }}
                              isTeacher={isTeacher}
                              onCheck={() => onCheckItem(task.id, itemId)}
                              onTeacherCheck={(status) =>
                                onTeacherCheckItem(task.id, itemId, status)
                              }
                              onPassFail={(status) =>
                                onPassFailItem(task.id, itemId, status)
                              }
                              onUpdateItem={(updatedItem) =>
                                onUpdateItem(task.id, itemId, updatedItem)
                              }
                              onDeleteItem={() => onDeleteItem(task.id, itemId)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}

          {/* 確認ダイアログ */}
          {confirmDialog && (
            <ConfirmDialog
              title={confirmDialog.title}
              message={confirmDialog.message}
              onConfirm={confirmDialog.onConfirm}
              onCancel={confirmDialog.onCancel}
            />
          )}
        </>
      )}
    </div>
  );
};

TaskCard.propTypes = {
  task: PropTypes.object.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  onUpdateTask: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  onTeacherCheckItem: PropTypes.func.isRequired,
  onPassFailItem: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  getStudentId: PropTypes.func.isRequired, // 親から渡される関数
  selectedSubject: PropTypes.object.isRequired, // 親から渡されるオブジェクト
  currentUser: PropTypes.object.isRequired, // 追加: currentUser をプロパティとして受け取る
};

// タスク追加フォームコンポーネント
const AddTaskForm = ({ onAddTask, onCancel }) => {
  const [title, setTitle] = useState('');
  const [items, setItems] = useState([
    { id: uuidv4(), text: '', dueDate: '', testDate: '', testScore: '' }
  ]);

  const handleAddItem = () => {
    setItems([...items, { id: uuidv4(), text: '', dueDate: '', testDate: '', testScore: '' }]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // バリデーション
    if (!title.trim() || items.some(item => !item.text.trim())) {
      alert('タイトルとすべてのチェック項目を入力してください。');
      return;
    }
    // チェック項目をオブジェクト形式に変換
    const itemsObject = items.reduce((acc, item, index) => {
      acc[item.id] = { ...item, order: index };
      return acc;
    }, {});
    onAddTask({ title, items: itemsObject });
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 bg-yellow-50 rounded-lg shadow">
      <div className="flex flex-col gap-4">
        <div>
          <label htmlFor="task-title" className="font-medium text-purple-700">課題タイトル</label>
          <input
            id="task-title" // 一意のIDを追加
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
            placeholder="例: 週末の数学課題"
            aria-label="課題タイトル入力"
          />
        </div>

        <div>
          <label className="font-medium text-purple-700">チェック項目</label>
          {items.map((item, index) => (
            <div key={item.id} className="flex flex-col gap-1 mb-3">
              <label htmlFor={`add-text-${item.id}`} className="sr-only">チェック項目 {index + 1}</label>
              <textarea
                id={`add-text-${item.id}`} // 一意のIDを追加
                value={item.text}
                onChange={(e) => {
                  const newItems = [...items];
                  newItems[index].text = e.target.value;
                  setItems(newItems);
                }}
                placeholder="例: 数学の問題を解く"
                className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                rows={2}
                required
                aria-label={`チェック項目 ${index + 1} 入力`}
              />
            </div>
          ))}
        </div>

        {/* 項目の追加ボタン */}
        <div className="flex justify-start mt-4">
          <button
            type="button"
            onClick={handleAddItem}
            className="text-purple-500 hover:text-purple-600 flex items-center gap-1"
            aria-label="項目を追加ボタン"
          >
            <PlusCircle size={20} />
            項目を追加
          </button>
        </div>

        {/* 保存とキャンセルボタン */}
        <div className="flex justify-between mt-6">
          <button
            type="submit"
            className="bg-purple-300 text-purple-800 px-5 py-2 rounded hover:bg-purple-400"
            aria-label="課題保存ボタン"
          >
            保存
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="bg-purple-100 text-purple-700 px-5 py-2 rounded hover:bg-purple-200"
            aria-label="課題編集キャンセルボタン"
          >
            キャンセル
          </button>
        </div>
      </div>
    </form>
  );
};

AddTaskForm.propTypes = {
  onAddTask: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// メインコンポーネント
const SubjectTaskBoard = () => {
  // Hooks の宣言
  const [currentUser, setCurrentUser] = useState(null);
  const [students, setStudents] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [tasks, setTasks] = useState({});
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(SUBJECTS[0]);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarTab, setSidebarTab] = useState('students');
  const [showBulkImport, setShowBulkImport] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isMessaging, setIsMessaging] = useState(false);
  const [messagingPartner, setMessagingPartner] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(FILTERS[0]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedPage, setSelectedPage] = useState('schoolWork'); // タブの状態を追加

  // isTeacher の定義
  const isTeacher = currentUser && (currentUser.role === 'teacher' || currentUser.isMaster);

  // PAGESを動的に再定義するためにuseMemoを使用
  const pages = useMemo(() => {
    const basePages = [
      { id: 'individualAssignments', name: '個別課題' },
      { id: 'schoolTaskSummary', name: '学校課題一覧' },
      { id: 'quiz', name: 'Web課題' },
    ];

    if (isTeacher && currentUser?.isMaster) {
      basePages.unshift({ id: 'schoolWork', name: '学校ワーク管理' });
      basePages.unshift({ id: 'QuizManagement', name: 'クイズ作成・管理' });
    }

    return basePages;
  }, [isTeacher, currentUser]);

  // エクスポート関数の追加
  const exportToExcel = () => {
    // マスター権限の確認
    if (!currentUser || !currentUser.isMaster) {
      alert('エクスポート権限がありません。マスター権限を持つ教師のみエクスポートできます。');
      return;
    }

    if (!selectedStudent) {
      alert('エクスポートする生徒を選択してください。');
      return;
    }

    if (!selectedSubject) {
      alert('エクスポートする教科を選択してください。');
      return;
    }

    const key = `${selectedStudent.id}-${selectedSubject.id}`;
    const tasksByStudentAndSubject = tasks[key] || {};

    const exportData = [];

    Object.values(tasksByStudentAndSubject).forEach((task) => {
      Object.values(task.items || {}).forEach((item) => {
        exportData.push({
          生徒名: selectedStudent.name,
          学年:
            GRADES.find((g) => g.id === selectedStudent.grade)?.name ||
            selectedStudent.grade,
          教科: SUBJECTS.find((s) => s.id === selectedSubject.id)?.name || selectedSubject.id,
          課題名: task.title,
          チェック項目: item.text,
          課題期限: item.dueDate,
          確認テスト日時: item.testDate,
          確認テストスコア: item.testScore,
          チェックボックス: item.checked ? 'true' : 'false',
        });
      });
    });

    if (exportData.length === 0) {
      alert('対象生徒に登録されている課題がありません。');
      return;
    }

    // エクセルワークブックとワークシートの作成
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tasks');

    // エクセルファイルのバイナリデータを取得
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Blobオブジェクトの作成
    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // ファイルのダウンロード
    saveAs(
      data,
      `${selectedStudent.name}_tasks_${new Date().toISOString().slice(0, 10)}.xlsx`
    );
  };

  // ハンドラー関数
  const handleOpenMessaging = (partner) => {
    setMessagingPartner(partner);
    setIsMessaging(true);
  };

  const handleCloseMessaging = () => {
    setIsMessaging(false);
    setMessagingPartner(null);
  };

  const handleOpenNotesModal = () => {
    console.log('メモモーダルを開きます'); // デバッグ用
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
  };

  const handleBulkImport = async (processedData) => {
    try {
      const updates = {};
      for (const [key, data] of Object.entries(processedData)) {
        updates[`tasks/${key}`] = {
          ...tasks[key],
          ...data.tasks,
        };
      }
      await update(ref(database), updates);
      setShowBulkImport(false);
    } catch (error) {
      console.error('Error saving bulk tasks:', error);
      alert('一括登録中にエラーが発生しました。再度お試しください。');
    }
  };

  const handleAddTeacher = async ({ name, username, password, isMaster }) => {
    try {
      if (isMaster && teachers.some((teacher) => teacher.isMaster)) {
        alert('既にマスターティーチャーが存在します。');
        return;
      }
      const newTeacherRef = push(ref(database, 'teachers'));
      const newTeacher = {
        id: newTeacherRef.key,
        name,
        username,
        password,
        isMaster,
      };
      await set(newTeacherRef, newTeacher);
      console.log('新しい教師が追加されました');
    } catch (error) {
      console.error('教師の追加に失敗しました:', error);
      alert('教師の追加に失敗しました。再度お試しください。');
    }
  };

  const handleUpdateTeacher = async (updatedTeacher) => {
    try {
      const teacherRef = ref(database, `teachers/${updatedTeacher.id}`);
      await set(teacherRef, updatedTeacher);
      console.log('教師情報が更新されました');
    } catch (error) {
      console.error('教師情報の更新に失敗しました:', error);
      alert('教師情報の更新に失敗しました。再度お試しください。');
    }
  };

  const handleDeleteTeacher = async (id) => {
    try {
      await remove(ref(database, `teachers/${id}`));
      console.log('教師が削除されました');
      if (selectedTeacher?.id === id) {
        setSelectedTeacher(null);
      }
    } catch (error) {
      console.error('教師の削除に失敗しました:', error);
      alert('教師の削除に失敗しました。再度お試しください。');
    }
  };

  // 課題の並び替えを処理する関数
  const handleDragEnd = async (result) => {
    console.log('Drag Ended:', result); // デバッグ用ログ
    const { destination, source, type } = result;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    try {
      // 生徒リストの並び替え
      if (
        source.droppableId.startsWith('studentList') &&
        destination.droppableId.startsWith('studentList')
      ) {
        if (!currentUser.isMaster) return;

        // 現在の生徒リストをソート
        const sortedStudents = [...students].sort((a, b) => (a.order || 0) - (b.order || 0));

        // 並び替え処理
        const reorderedStudents = Array.from(sortedStudents);
        const [movedStudent] = reorderedStudents.splice(source.index, 1);
        reorderedStudents.splice(destination.index, 0, movedStudent);

        // 並び替え後のorderを設定
        const updatedStudents = reorderedStudents.map((student, index) => ({
          ...student,
          order: index,
        }));

        // ステートの更新（即時反映）
        setStudents(updatedStudents);

        // Firebase に order を更新
        const updates = {};
        updatedStudents.forEach((student, index) => {
          updates[`students/${student.id}/order`] = index;
        });

        await update(ref(database), updates);
        console.log('生徒リストの順序が更新されました');
        return;
      }

      // タスクの並び替え
      if (type === 'task') {
        const studentId = getStudentId();
        if (!studentId) return;
        const key = `${studentId}-${selectedSubject.id}`;

        const taskList = tasks[key]
          ? Object.values(tasks[key]).sort((a, b) => (a.order || 0) - (b.order || 0))
          : [];

        const reorderedTasks = Array.from(taskList);
        const [movedTask] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, movedTask);

        const taskUpdates = {};
        reorderedTasks.forEach((task, index) => {
          taskUpdates[`tasks/${key}/${task.id}/order`] = index;
        });

        await update(ref(database), taskUpdates);
        console.log('タスクの順序が更新されました');
        return;
      }

      // チェック項目の並び替え
      if (type.startsWith('taskItems-')) {
        const taskId = type.replace('taskItems-', '');
        const studentId = getStudentId();
        if (!studentId) return;
        const key = `${studentId}-${selectedSubject.id}`;
        const task = tasks[key]?.[taskId];
        if (!task) return;

        const itemList = Object.entries(task.items || {}).sort(
          ([, a], [, b]) => (a.order || 0) - (b.order || 0)
        );

        const reorderedItems = Array.from(itemList);
        const [movedItem] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, movedItem);

        const itemUpdates = {};
        reorderedItems.forEach(([itemId], index) => {
          itemUpdates[`tasks/${key}/${taskId}/items/${itemId}/order`] = index;
        });

        await update(ref(database), itemUpdates);
        console.log('チェック項目の順序が更新されました');
        return;
      }

    } catch (error) {
      console.error('並び替えの更新に失敗しました:', error);
      alert('並び替えの更新に失敗しました。再度お試しください。');
    }
  };

  const handlePassFailItem = async (taskId, itemId, status) => {
    try {
      const studentId = getStudentId();
      const key = `${studentId}-${selectedSubject.id}`;
      const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);

      const snapshot = await get(itemRef);
      const currentItem = snapshot.val();

      if (currentItem) {
        const newStatus = currentItem.passStatus === status ? null : status;
        await update(itemRef, { passStatus: newStatus });
        console.log(`合否判定を${newStatus || 'リセット'}に更新しました`);
      } else {
        console.error('指定された項目が見つかりません');
        throw new Error('Item not found');
      }
    } catch (error) {
      console.error('合否判定の更新に失敗しました:', error);
      alert('合否判定の更新に失敗しました。再度お試しください。');
    }
  };

  const handleLogin = (username, password) => {
    if (!isDataLoaded) {
      alert('データを読み込み中です。しばらくお待ちください。');
      return;
    }

    const teacher = teachers.find(
      (t) => t.username === username && t.password === password
    );
    if (teacher) {
      setCurrentUser({
        ...teacher,
        role: 'teacher',
        isMaster: teacher.isMaster || false,
      });
      setSelectedStudent(null);
      setSelectedTeacher(null);
      setIsSidebarOpen(false); // 教師ログイン時にサイドバーを開かない設定
      return;
    }

    const student = students.find(
      (s) => s.username === username && s.password === password
    );
    if (student) {
      setCurrentUser({ ...student, role: 'student' });
      setSelectedStudent(null);
      setSelectedTeacher(null);
      setIsSidebarOpen(false);
    } else {
      alert('ユーザー名またはパスワードが間違っています');
    }
  };

  const getStudentId = () => {
    if (currentUser && currentUser.role === 'student') {
      return currentUser.id;
    } else if (selectedStudent) {
      return selectedStudent.id;
    }
    return null;
  };

  useEffect(() => {
    // 生徒が学校ワーク管理タブを選択している場合、学校課題一覧タブに自動で切り替え
    if (!isTeacher && selectedPage === 'schoolWork') {
      setSelectedPage('schoolTaskSummary');
    }
  }, [isTeacher, selectedPage]);

  // データの取得
  useEffect(() => {
    const teachersRef = ref(database, 'teachers');
    const unsubscribeTeachers = onValue(teachersRef, async (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const teacherList = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setTeachers(teacherList);
      } else {
        setTeachers([]);
        const newTeacherRef = push(ref(database, 'teachers'));
        const newTeacher = {
          id: newTeacherRef.key,
          name: 'マスター教師',
          username: 'master',
          password: 'password',
          isMaster: true,
        };
        await set(newTeacherRef, newTeacher);
        setTeachers([newTeacher]);
      }

      setIsDataLoaded(true);
    });

    const studentsRef = ref(database, 'students');
    const unsubscribeStudents = onValue(studentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // orderプロパティを確実に設定
        const studentList = Object.entries(data).map(([id, student]) => ({
          ...student,
          id,
          order: student.order || 0 // orderが未設定の場合は0を設定
        }));
        // 順序でソート
        studentList.sort((a, b) => (a.order || 0) - (b.order || 0));
        setStudents(studentList);
      } else {
        setStudents([]);
      }
    });

    return () => {
      unsubscribeTeachers();
      unsubscribeStudents();
    };
  }, []);

  // タスクデータの取得
  useEffect(() => {
    const studentId = getStudentId();
    if (!studentId) return;

    // 全科目のタスクを一度に取得
    const tasksRef = ref(database, 'tasks');
    const unsubscribeTasks = onValue(tasksRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        // 現在の生徒のタスクをフィルタリング
        const studentTasks = Object.keys(data).reduce((acc, key) => {
          if (key.startsWith(`${studentId}-`)) {
            acc[key] = data[key];
          }
          return acc;
        }, {});
        setTasks(studentTasks);
      } else {
        setTasks({});
      }
      console.log('Fetched tasks:', data); // デバッグ用
    });

    return () => unsubscribeTasks();
  }, [selectedStudent, currentUser, selectedSubject]);

  // ハンドラー関数は一度だけ宣言
  const handleDeleteItem = async (taskId, itemId) => {
    const studentId = getStudentId();
    const key = `${studentId}-${selectedSubject.id}`;
    const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);
    try {
      await remove(itemRef);
      console.log('チェック項目が削除されました');
    } catch (error) {
      console.error('チェック項目の削除に失敗しました:', error);
      alert('チェック項目の削除に失敗しました。再度お試しください。');
    }
  };

  const handleUpdateItem = async (taskId, itemId, updatedItem) => {
    try {
      const studentId = getStudentId();
      const key = `${studentId}-${selectedSubject.id}`;
      const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);
      await update(itemRef, updatedItem);
      console.log('チェック項目が更新されました');
    } catch (error) {
      console.error('チェック項目の更新に失敗しました:', error);
      alert('チェック項目の更新に失敗しました。再度お試しください。');
    }
  };

  // タスクリストをレンダリングする関数を定義
  const renderTaskList = () => {
    const studentId = getStudentId();
    if (!studentId) return null;
    const key = `${studentId}-${selectedSubject.id}`;

    const taskList = tasks[key]
      ? Object.values(tasks[key]).sort((a, b) => (a.order || 0) - (b.order || 0))
      : [];

    const filteredTaskList = taskList.filter((task) => {
      if (selectedFilter.id === 'all') {
        return true;
      }

      const items = Object.values(task.items || {});
      if (selectedFilter.id === 'dueAndNotConfirmed') {
        return items.some((item) => item.dueDate && !item.teacherCheck);
      }

      if (selectedFilter.id === 'noTestDate') {
        return items.some((item) => !item.testDate);
      }

      if (selectedFilter.id === 'failedConfirmation') {
        return items.some((item) => item.passStatus === 'fail');
      }

      return false;
    });

    return (
      <Droppable droppableId="tasksDroppable" type="task">
        {(provided) => (
          <div
            className="space-y-6"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {filteredTaskList.map((task, index) => (
              <Draggable
                key={task.id}
                draggableId={`task-${task.id}`}
                index={index}
                isDragDisabled={!isTeacher}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <TaskCard
                      task={task}
                      isTeacher={isTeacher}
                      onDeleteTask={() => {
                        setConfirmDialog({
                          title: '課題の削除',
                          message: 'この課題を削除してもよろしいですか？',
                          onConfirm: () => {
                            remove(ref(database, `tasks/${key}/${task.id}`));
                            setConfirmDialog(null);
                          },
                          onCancel: () => setConfirmDialog(null),
                        });
                      }}
                      onUpdateTask={(updatedTask) => {
                        set(ref(database, `tasks/${key}/${updatedTask.id}`), updatedTask);
                      }}
                      onCheckItem={(taskId, itemId) => {
                        const itemRef = ref(
                          database,
                          `tasks/${key}/${taskId}/items/${itemId}`
                        );
                        const currentItem = tasks[key]?.[taskId]?.items?.[itemId];
                        if (currentItem) {
                          const currentChecked = currentItem.checked || false;
                          update(itemRef, { checked: !currentChecked })
                            .then(() => {
                              console.log('Checked status updated successfully');
                            })
                            .catch((error) => {
                              console.error('Error updating checked status:', error);
                              alert('チェック状態の更新に失敗しました。再度お試しください。');
                            });
                        }
                      }}
                      onTeacherCheckItem={(taskId, itemId, status) => {
                        const itemRef = ref(
                          database,
                          `tasks/${key}/${taskId}/items/${itemId}`
                        );
                        update(itemRef, { teacherCheck: status })
                          .then(() => {
                            console.log('教師確認が更新されました');
                          })
                          .catch((error) => {
                            console.error('教師確認の更新に失敗しました:', error);
                            alert('教師確認の更新に失敗しました。再度お試しください。');
                          });
                      }}
                      onPassFailItem={handlePassFailItem}
                      onUpdateItem={handleUpdateItem}
                      onDeleteItem={handleDeleteItem}
                      getStudentId={getStudentId}
                      selectedSubject={selectedSubject}
                      currentUser={currentUser}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  // ローディング状態とログイン状態の管理
  if (!isDataLoaded) {
    return <div className="text-purple-700">データを読み込み中...</div>;
  }

  if (!currentUser) {
    return <Login onLogin={handleLogin} />;
  }

  // タブのコンテンツをレンダリングする関数を定義
  const renderTabContent = () => {
    switch (selectedPage) {
      case 'QuizManagement':
        return <QuizManagement currentUser={currentUser} />;
      case 'schoolWork':
        if (!currentUser?.isMaster) {
          return <SchoolTaskSummary /* 適切なプロパティを渡す */ />;
        }
        return (
          <>
            <SubjectTabs
              subjects={SUBJECTS}
              selectedSubject={selectedSubject}
              onSelectSubject={setSelectedSubject}
            />

            {isTeacher && currentUser?.isMaster && selectedStudent && (
              <div className="mb-6">
                <button
                  onClick={() => setShowBulkImport(!showBulkImport)}
                  className="w-full py-4 border-2 border-dashed border-purple-300 rounded-lg hover:border-purple-500 hover:text-purple-500 flex items-center justify-center gap-2 transition-colors duration-200"
                  aria-label="課題を一括登録"
                >
                  一括登録
                </button>

                {/* BulkTaskImport コンポーネントを条件付きでレンダリング */}
                {showBulkImport && (
                  <BulkTaskImport
                    students={students}
                    onImport={handleBulkImport}
                    selectedStudent={selectedStudent}
                    selectedSubject={selectedSubject}
                    tasksByStudentAndSubject={
                      tasks[`${selectedStudent.id}-${selectedSubject.id}`]
                    }
                  />
                )}
              </div>
            )}

            <div className="space-y-6">
              {renderTaskList()}

              {isTeacher && currentUser?.isMaster && selectedStudent && (
                isAddingTask ? (
                  <AddTaskForm
                    onAddTask={(newTask) => {
                      const key = `${selectedStudent.id}-${selectedSubject.id}`;
                      const taskId = uuidv4();
                      const taskWithId = {
                        ...newTask,
                        id: taskId,
                      };
                      set(ref(database, `tasks/${key}/${taskWithId.id}`), taskWithId);
                      setIsAddingTask(false);
                    }}
                    onCancel={() => setIsAddingTask(false)}
                  />
                ) : (
                  <button
                    onClick={() => setIsAddingTask(true)}
                    className="w-full py-4 border-2 border-dashed border-purple-300 rounded-lg hover:border-purple-500 hover:text-purple-500 flex items-center justify-center gap-2 transition-colors duration-200"
                    aria-label="新しい課題を追加"
                  >
                    <PlusCircle size={20} />
                    新しい課題を追加
                  </button>
                )
              )}
            </div>
          </>
        );
      case 'individualAssignments':
        return (
          // 【個別課題】ページのコンテンツ
          <IndividualAssignments
            selectedStudent={selectedStudent}
            currentUser={currentUser}
          />
        );
      case 'schoolTaskSummary':
        return (
          <SchoolTaskSummary
            tasks={tasks}
            selectedStudent={selectedStudent || currentUser}
            subjects={SUBJECTS}
            isTeacher={isTeacher}
            onCheckItem={(subjectId, taskId, itemId) => {
              // チェックボックスの更新（教師・生徒共に使用可能）
              const studentId = getStudentId();
              if (!studentId) return;

              const key = `${studentId}-${subjectId}`;
              const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);

              const currentItem = tasks[key]?.[taskId]?.items?.[itemId];

              if (currentItem) {
                const currentChecked = currentItem.checked || false;
                update(itemRef, { checked: !currentChecked })
                  .then(() => {
                    console.log('チェック状態を更新しました');
                  })
                  .catch((error) => {
                    console.error('チェック状態の更新に失敗しました:', error);
                    alert('チェック状態の更新に失敗しました。再度お試しください。');
                  });
              }
            }}
            onTeacherCheckItem={(subjectId, taskId, itemId, status) => {
              // 教師の確認マークの更新（教師のみ使用可能）
              if (!isTeacher) return;

              const studentId = getStudentId();
              if (!studentId) return;

              const key = `${studentId}-${subjectId}`;
              const itemRef = ref(database, `tasks/${key}/${taskId}/items/${itemId}`);

              const currentItem = tasks[key]?.[taskId]?.items?.[itemId];

              if (currentItem) {
                // 同じステータスをクリックした場合は解除する
                const newStatus = currentItem.teacherCheck === status ? null : status;
                update(itemRef, { teacherCheck: newStatus })
                  .then(() => {
                    console.log('教師確認を更新しました');
                  })
                  .catch((error) => {
                    console.error('教師確認の更新に失敗しました:', error);
                    alert('教師確認の更新に失敗しました。再度お試しください。');
                  });
              }
            }}
          />
        );
      case 'quiz':
        return (
          // 新しい一問一答コンテンツ
          <QuizSection
            currentUser={currentUser}
            selectedStudent={selectedStudent}
            isTeacher={isTeacher}
          />
        );
      default:
        return null;
    }
  };

  // 残りのコンポーネントのレンダリング処理
  return (
    <div className="bg-yellow-50 min-h-screen">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Navbar
          currentUser={currentUser}
          selectedStudent={selectedStudent}
          selectedTeacher={selectedTeacher}
          onLogout={() => {
            setCurrentUser(null);
            setSelectedStudent(null);
            setSelectedTeacher(null);
          }}
          onToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
          onOpenMessaging={handleOpenMessaging}
          teachers={teachers}
          onOpenNotes={handleOpenNotesModal}
          onExport={exportToExcel}
        />

        <div className="max-w-screen-xl mx-auto px-4 md:flex md:gap-6">
          {/* サイドバー */}
          {(currentUser.role === 'teacher' || currentUser.isMaster) && (
            <Sidebar
              isSidebarOpen={isSidebarOpen}
              setIsSidebarOpen={setIsSidebarOpen}
              isMaster={currentUser.isMaster}
              sidebarTab={sidebarTab}
              setSidebarTab={setSidebarTab}
              students={students}
              selectedStudent={selectedStudent}
              onSelectStudent={setSelectedStudent}
              onAddStudent={({ name, username, password, grade }) => {
                const newStudentRef = push(ref(database, 'students'));
                const newStudent = {
                  id: newStudentRef.key,
                  name,
                  username,
                  password,
                  grade,
                  order: students.length, // 初期 order を設定
                };
                set(newStudentRef, newStudent);
              }}
              onUpdateStudent={(updatedStudent) => {
                set(ref(database, `students/${updatedStudent.id}`), updatedStudent);
              }}
              onDeleteStudent={(id) => {
                remove(ref(database, `students/${id}`));
                if (selectedStudent?.id === id) {
                  setSelectedStudent(null);
                }
              }}
              teachers={teachers}
              selectedTeacher={selectedTeacher}
              onSelectTeacher={setSelectedTeacher}
              onAddTeacher={handleAddTeacher}
              onUpdateTeacher={handleUpdateTeacher}
              onDeleteTeacher={handleDeleteTeacher}
              currentUser={currentUser}
              onOpenMessaging={handleOpenMessaging}
            />
          )}

          {/* メインコンテンツ */}
          <div className="flex-1">
            {(currentUser.role === 'student' || currentUser.role === 'teacher' || selectedStudent || selectedTeacher) && (
              <>
                {/* トップレベルのタブナビゲーション */}
                <div className="mb-6">
                  {/* タブのヘッダー */}
                  <div className="flex border-b border-purple-300">
                    {pages.map((page) => (
                      <button
                        key={page.id}
                        onClick={() => setSelectedPage(page.id)}
                        className={`flex-1 px-4 py-2 -mb-px font-medium transition-colors duration-200 ${
                          selectedPage === page.id
                            ? 'border-b-2 border-purple-700 text-purple-700'
                            : 'text-purple-500 hover:text-purple-700'
                        } cursor-pointer`}
                        aria-label={`ページタブ: ${page.name}`}
                      >
                        {page.name}
                      </button>
                    ))}
                  </div>
                </div>

                {/* タブのコンテンツ */}
                <div>
                  {renderTabContent()}
                </div>

                {/* 教師のみメモモーダルを表示 */}
                {isNotesModalOpen && (
                  <TeacherNotesModal
                    selectedStudent={selectedStudent}
                    currentUser={currentUser}
                    onClose={handleCloseNotesModal}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {confirmDialog && (
          <ConfirmDialog
            title={confirmDialog.title}
            message={confirmDialog.message}
            onConfirm={confirmDialog.onConfirm}
            onCancel={confirmDialog.onCancel}
          />
        )}

        {/* メッセージングモーダルの表示 */}
        {isMessaging && (
          <MessagingModal
            currentUser={currentUser}
            partner={messagingPartner}
            onClose={handleCloseMessaging}
          />
        )}
      </DragDropContext>
    </div>
  );
};

// PropTypes の定義（必要に応じて調整）
SubjectTaskBoard.propTypes = {
  // 必要なプロパティがあればここに定義
};

export default SubjectTaskBoard;
