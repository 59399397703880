import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { ref, push, set } from 'firebase/database';
import { database } from './config/firebase';
import { Upload, AlertTriangle, Download } from 'lucide-react';

const SUBJECTS = {
  '英語': 'english',
  '数学': 'math',
  '国語': 'japanese',
  '理科': 'science',
  '社会': 'social'
};

const QuizBulkUpload = ({ onClose }) => {
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const validateContent = (content) => {
    if (!content.コンテンツ名 || !content.教科 || !content.出題順序 || !content.問題移動 || !content.解答表示タイミング) {
      return false;
    }
    if (!SUBJECTS[content.教科]) {
      return false;
    }
    if (!['ランダム', '固定'].includes(content.出題順序)) {
      return false;
    }
    if (!['順方向のみ', '自由'].includes(content.問題移動)) {
      return false;
    }
    if (!['即時', '最後'].includes(content.解答表示タイミング)) {
      return false;
    }
    return true;
  };

  const validateQuiz = (quiz) => {
    if (!quiz.タイプ || !quiz.問題文) {
      return false;
    }

    switch (quiz.タイプ) {
      case '選択':
        if (!quiz.正解) return false;
        return quiz.選択肢1 && quiz.選択肢2 && quiz.選択肢3 && quiz.選択肢4;

      case '並び替え':
        return quiz.英文 && quiz.日本語訳;

      case '正誤':
        if (!quiz.正解) return false;
        return ['true', 'false'].includes(quiz.正解.toLowerCase());

      case '穴埋め':
        return quiz.正解 !== undefined && quiz.正解 !== null;

      case 'マッチング':
        return quiz.問題文 && (
          (quiz.左項目1 && quiz.右項目1) ||
          (quiz.左項目2 && quiz.右項目2) ||
          (quiz.左項目3 && quiz.右項目3) ||
          (quiz.左項目4 && quiz.右項目4)
        );

      default:
        return false;
    }
  };

  const generateTemplate = () => {
    const contentsData = [
      {
        'コンテンツ名': '例：基本英単語テスト',
        '教科': '英語',
        '出題順序': 'ランダム',
        '問題移動': '順方向のみ',
        '解答表示タイミング': '即時',
        '問題ごとの制限時間': '30',
        '全体制限時間': '0'
      },
      {
        'コンテンツ名': '例：数学確認テスト',
        '教科': '数学',
        '出題順序': '固定',
        '問題移動': '自由',
        '解答表示タイミング': '最後',
        '問題ごとの制限時間': '0',
        '全体制限時間': '300'
      }
    ];

    const quizzesData = [
      {
        'コンテンツ名': '例：基本英単語テスト',
        'タイプ': '選択',
        '問題文': 'appleの意味は？',
        '選択肢1': 'りんご',
        '選択肢2': 'バナナ',
        '選択肢3': 'オレンジ',
        '選択肢4': 'ぶどう',
        '正解': 'りんご',
        '解説': 'appleはりんごという意味です。'
      },
      {
        'コンテンツ名': '例：基本英単語テスト',
        'タイプ': '並び替え',
        '問題文': '以下の単語を並び替えて正しい英文を作りましょう。',
        '英文': 'I am a student.',
        '日本語訳': '私は学生です。',
        '正解': 'I am a student.',
        '解説': '基本的な be動詞を使った文です。'
      },
      {
        'コンテンツ名': '例：基本英単語テスト',
        'タイプ': '正誤',
        '問題文': 'appleは野菜である。',
        '正解': 'false',
        '解説': 'appleは果物です。'
      },
      {
        'コンテンツ名': '例：基本英単語テスト',
        'タイプ': '穴埋め',
        '問題文': 'I ___ a student.',
        '正解': 'am',
        '解説': 'I の後には be動詞の am が来ます。'
      },
      {
        'コンテンツ名': '例：基本英単語テスト',
        'タイプ': 'マッチング',
        '問題文': '英単語と日本語の意味を正しく結びつけてください。',
        '左項目1': 'apple',
        '右項目1': 'りんご',
        'マッチング1': '1',
        '左項目2': 'banana',
        '右項目2': 'バナナ',
        'マッチング2': '2',
        '解説': '基本的な果物の単語です。'
      }
    ];

    const wb = XLSX.utils.book_new();
    const wsContents = XLSX.utils.json_to_sheet(contentsData);
    const wsQuizzes = XLSX.utils.json_to_sheet(quizzesData);
    XLSX.utils.book_append_sheet(wb, wsContents, 'コンテンツ');
    XLSX.utils.book_append_sheet(wb, wsQuizzes, 'クイズ');
    XLSX.writeFile(wb, 'quiz_template.xlsx');
  };
  
  const processExcelFile = async (file) => {
    try {
      setIsUploading(true);
      setError(null);

      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);

      const contentsSheet = workbook.Sheets['コンテンツ'];
      if (!contentsSheet) {
        throw new Error('コンテンツシートが見つかりません');
      }
      const contents = XLSX.utils.sheet_to_json(contentsSheet);

      const quizzesSheet = workbook.Sheets['クイズ'];
      if (!quizzesSheet) {
        throw new Error('クイズシートが見つかりません');
      }
      const quizzes = XLSX.utils.sheet_to_json(quizzesSheet);

      for (const content of contents) {
        if (!validateContent(content)) {
          throw new Error(`無効なコンテンツデータ: ${content.コンテンツ名}`);
        }

        const contentQuizzes = quizzes.filter(q => q.コンテンツ名 === content.コンテンツ名);
        const quizzesData = {};

        for (const quiz of contentQuizzes) {
          if (!validateQuiz(quiz)) {
            throw new Error(`無効なクイズデータ: ${quiz.問題文}`);
          }

          const quizId = push(ref(database, 'temporaryRef')).key;
          const quizData = {
            type: getQuizType(quiz.タイプ),
            subject: SUBJECTS[content.教科],
            timestamp: Date.now(),
            explanation: quiz.解説 || ''
          };

          switch (quiz.タイプ) {
            case '選択':
              quizData.question = quiz.問題文;
              quizData.choices = [quiz.選択肢1, quiz.選択肢2, quiz.選択肢3, quiz.選択肢4];
              quizData.answer = quiz.正解;
              break;
            case '並び替え':
              quizData.englishSentence = quiz.英文;
              quizData.japaneseTranslation = quiz.日本語訳;
              break;
            case '正誤':
              quizData.question = quiz.問題文;
              quizData.answer = quiz.正解.toLowerCase() === 'true';
              break;
            case '穴埋め':
              quizData.question = quiz.問題文;
              quizData.answer = quiz.正解;
              break;
            case 'マッチング':
              quizData.question = quiz.問題文;
              quizData.leftItems = [];
              quizData.rightItems = [];
              quizData.correctMatches = [];
              
              for (let i = 1; i <= 4; i++) {
                const leftItem = quiz[`左項目${i}`];
                const rightItem = quiz[`右項目${i}`];
                const matching = quiz[`マッチング${i}`];
                
                if (leftItem && rightItem) {
                  quizData.leftItems.push(leftItem);
                  quizData.rightItems.push(rightItem);
                  quizData.correctMatches.push(matching ? parseInt(matching) - 1 : i - 1);
                }
              }
              break;
          }

          quizzesData[quizId] = quizData;
        }

        const contentRef = push(ref(database, 'contents'));
        const contentData = {
          title: content.コンテンツ名,
          subject: SUBJECTS[content.教科],
          randomizeQuizzes: content.出題順序 === 'ランダム',
          freeNavigation: content.問題移動 === '自由',
          answerRevealMode: content.解答表示タイミング === '即時' ? 'immediate' : 'end',
          timeLimitPerQuestion: parseInt(content.問題ごとの制限時間) || 0,
          timeLimitPerContent: parseInt(content.全体制限時間) || 0,
          quizzes: quizzesData
        };

        await set(contentRef, contentData);
      }

      alert('コンテンツとクイズを一括登録しました');
      onClose();
    } catch (error) {
      console.error('アップロードエラー:', error);
      setError(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const getQuizType = (type) => {
    switch (type) {
      case '選択': return 'multiple-choice';
      case '並び替え': return 'sentence-rearrangement';
      case '正誤': return 'true-false';
      case '穴埋め': return 'fill-in-the-blank';
      case 'マッチング': return 'matching';
      default: throw new Error(`不明なクイズタイプ: ${type}`);
    }
  };


// すべてのドラッグ&ドロップハンドラーをまとめて定義
const [dragCounter, setDragCounter] = useState(0);

const handleDragEnter = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
  setDragCounter(prev => prev + 1);
  setIsDragging(true);
}, []);

const handleDragLeave = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
  setDragCounter(prev => prev - 1);
  if (dragCounter === 1) {
    setIsDragging(false);
  }
}, [dragCounter]);

const handleDragOver = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
}, []);

const handleDrop = useCallback((e) => {
  e.preventDefault();
  e.stopPropagation();
  setDragCounter(0);
  setIsDragging(false);

  const file = e.dataTransfer.files[0];
  if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    processExcelFile(file);
  } else {
    setError('Excelファイル(.xlsx)のみアップロード可能です。');
  }
}, []);

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h3 className="text-xl font-semibold text-purple-700 mb-4">
        コンテンツ一括登録
      </h3>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
          <div className="flex items-center gap-2 text-red-600">
            <AlertTriangle size={20} />
            <span>{error}</span>
          </div>
        </div>
      )}

      <div className="space-y-4">
        <div>
          <p className="text-gray-600 mb-2">
            コンテンツとクイズのデータをエクセルファイルから一括登録できます。
          </p>
          <button
            onClick={generateTemplate}
            className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
          >
            <Download size={20} />
            テンプレートファイルをダウンロード
          </button>
        </div>

        <div
  className={`relative border-2 ${
    isDragging ? 'border-purple-500 bg-purple-50' : 'border-dashed border-purple-200'
  } rounded-lg p-8 transition-all duration-300`}
  onDragEnter={handleDragEnter}
  onDragOver={handleDragOver}
  onDragLeave={handleDragLeave}
  onDrop={handleDrop}
>
  <input
    type="file"
    accept=".xlsx"
    onChange={(e) => {
      const file = e.target.files[0];
      if (file) processExcelFile(file);
    }}
    className="hidden"
    id="excel-upload"
    disabled={isUploading}
  />
  <label
    htmlFor="excel-upload"
    className={`flex flex-col items-center justify-center cursor-pointer
      ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    <Upload size={40} className="text-purple-500 mb-2" />
    <div className="text-purple-700 text-center">
      <p className="mb-1">
        {isUploading ? 'アップロード中...' : 'クリックしてファイルを選択'}
      </p>
      <p className="text-sm text-purple-500">
        またはファイルをドラッグ&ドロップ
      </p>
    </div>
  </label>

  {/* オーバーレイを絶対位置で配置し、transitionを追加 */}
  <div 
    className={`absolute inset-0 bg-purple-50 bg-opacity-90 flex items-center justify-center rounded-lg pointer-events-none
      transition-opacity duration-300 ${isDragging ? 'opacity-100' : 'opacity-0'}`}
  >
    <p className="text-purple-700 text-lg font-medium">
      ファイルをドロップしてアップロード
    </p>
  </div>
</div>

        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            disabled={isUploading}
          >
            閉じる
          </button>
        </div>
      </div>
    </div>
  );
};

QuizBulkUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default QuizBulkUpload;
  
  
  