// Sidebar.js

import React from 'react';
import PropTypes from 'prop-types';
import StudentList from './StudentList';
import TeacherList from './TeacherList';
import { X } from 'lucide-react';

const Sidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  isMaster,
  sidebarTab,
  setSidebarTab,
  students,
  selectedStudent,
  onSelectStudent,
  onAddStudent,
  onUpdateStudent,
  onDeleteStudent,
  teachers,
  selectedTeacher,
  onSelectTeacher,
  onAddTeacher,
  onUpdateTeacher,
  onDeleteTeacher,
  currentUser,
  onOpenMessaging,
}) => {
  return (
    <>
      {/* 共通サイドバーコンテナ */}
      <div
        className={`fixed inset-y-0 left-0 z-40 w-72 bg-yellow-50 shadow-lg overflow-y-auto transform transition-transform duration-300 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 md:static md:inset-auto md:w-72`}
      >
        {/* サイドバーヘッダー */}
        <div className="flex justify-between items-center p-4 border-b border-purple-300 md:hidden">
          <h2 className="text-lg font-semibold text-purple-700">サイドバー</h2>
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="text-gray-700"
            aria-label="サイドバーを閉じる"
          >
            <X size={24} />
          </button>
        </div>

        {/* サイドバーコンテンツ */}
        <div className="p-4">
          {isMaster ? (
            <>
              {/* タブ切り替えボタン */}
              <div className="flex mb-4">
                <button
                  onClick={() => setSidebarTab('students')}
                  className={`flex-1 py-2 text-center ${
                    sidebarTab === 'students'
                      ? 'bg-purple-500 text-white'
                      : 'bg-purple-200 text-purple-700'
                  }`}
                  aria-label="生徒一覧タブ"
                >
                  生徒一覧
                </button>
                <button
                  onClick={() => setSidebarTab('teachers')}
                  className={`flex-1 py-2 text-center ${
                    sidebarTab === 'teachers'
                      ? 'bg-purple-500 text-white'
                      : 'bg-purple-200 text-purple-700'
                  }`}
                  aria-label="教師一覧タブ"
                >
                  教師一覧
                </button>
              </div>
              {sidebarTab === 'students' ? (
                <StudentList
                  droppableId="studentList" // 共通の droppableId を使用
                  students={students}
                  selectedStudent={selectedStudent}
                  onSelectStudent={onSelectStudent}
                  onAddStudent={onAddStudent}
                  onUpdateStudent={onUpdateStudent}
                  onDeleteStudent={onDeleteStudent}
                  currentUser={currentUser}
                  onOpenMessaging={onOpenMessaging}
                />
              ) : (
                <TeacherList
                  teachers={teachers}
                  selectedTeacher={selectedTeacher}
                  onSelectTeacher={onSelectTeacher}
                  onAddTeacher={onAddTeacher}
                  onUpdateTeacher={onUpdateTeacher}
                  onDeleteTeacher={onDeleteTeacher}
                  currentUser={currentUser}
                  onOpenMessaging={onOpenMessaging}
                />
              )}
            </>
          ) : (
            <StudentList
              droppableId="studentList" // 共通の droppableId を使用
              students={students}
              selectedStudent={selectedStudent}
              onSelectStudent={onSelectStudent}
              onAddStudent={onAddStudent}
              onUpdateStudent={onUpdateStudent}
              onDeleteStudent={onDeleteStudent}
              currentUser={currentUser}
              onOpenMessaging={onOpenMessaging}
            />
          )}
        </div>
      </div>

      {/* オーバーレイ（モバイル時のみ表示） */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black bg-opacity-50 md:hidden"
          onClick={() => setIsSidebarOpen(false)}
          aria-hidden="true"
        ></div>
      )}
    </>
  );
};

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  setIsSidebarOpen: PropTypes.func.isRequired,
  isMaster: PropTypes.bool.isRequired,
  sidebarTab: PropTypes.string.isRequired,
  setSidebarTab: PropTypes.func.isRequired,
  students: PropTypes.array.isRequired,
  selectedStudent: PropTypes.object,
  onSelectStudent: PropTypes.func.isRequired,
  onAddStudent: PropTypes.func.isRequired,
  onUpdateStudent: PropTypes.func.isRequired,
  onDeleteStudent: PropTypes.func.isRequired,
  teachers: PropTypes.array.isRequired,
  selectedTeacher: PropTypes.object,
  onSelectTeacher: PropTypes.func.isRequired,
  onAddTeacher: PropTypes.func.isRequired,
  onUpdateTeacher: PropTypes.func.isRequired,
  onDeleteTeacher: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  onOpenMessaging: PropTypes.func.isRequired,
};

export default Sidebar;
