import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LogOut, Menu, MessageSquare, FileText, Download } from 'lucide-react';
import ConfirmDialog from './ConfirmDialog';
import useUnreadMessages from './hooks/useUnreadMessages';
import { useUnreadNotesCount } from './hooks/useUnreadNotesCount';

const GRADES = [
  { id: 'shougaku1', name: '小1' },
  { id: 'shougaku2', name: '小2' },
  { id: 'shougaku3', name: '小3' },
  { id: 'shougaku4', name: '小4' },
  { id: 'shougaku5', name: '小5' },
  { id: 'shougaku6', name: '小6' },
  { id: 'chuugaku1', name: '中1' },
  { id: 'chuugaku2', name: '中2' },
  { id: 'chuugaku3', name: '中3' },
  { id: 'koukou1', name: '高1' },
  { id: 'koukou2', name: '高2' },
  { id: 'koukou3', name: '高3' }
];

const Navbar = ({
  currentUser,
  selectedStudent,
  selectedTeacher,
  onLogout,
  onToggleSidebar,
  onOpenMessaging,
  onOpenNotes,
  teachers,
  onExport,
}) => {
  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);
  const { getUnreadCount } = useUnreadMessages(currentUser);

  const getGradeName = (gradeId) => {
    const grade = GRADES.find((g) => g.id === gradeId);
    return grade ? grade.name : gradeId;
  };

  const masterTeacher = useMemo(() => {
    return teachers?.find((teacher) => teacher.isMaster);
  }, [teachers]);

  const conversationId = useMemo(() => {
    if (currentUser.role === 'student' && masterTeacher) {
      return currentUser.id < masterTeacher.id
        ? `${currentUser.id}_${masterTeacher.id}`
        : `${masterTeacher.id}_${currentUser.id}`;
    }
    return null;
  }, [currentUser, masterTeacher]);

  const unreadCount = conversationId ? getUnreadCount(conversationId) : 0;

  const { unreadNotesCount } = useUnreadNotesCount(currentUser, selectedStudent);

  return (
    <nav className="bg-yellow-50 border-b mb-6 md:mb-8">
      <div className="max-w-screen-xl mx-auto p-4 flex flex-col">
        <div className="flex items-center mb-2">
          {currentUser.role === 'teacher' && (
            <button
              onClick={onToggleSidebar}
              className="md:hidden text-purple-500 focus:outline-none mr-4"
              aria-label="サイドバーを開く"
            >
              <Menu size={24} />
            </button>
          )}
          <h1 className="text-xl md:text-2xl font-bold text-purple-700 flex-1">
            S-Plus 課題管理
          </h1>
        </div>

        <div className="flex justify-end items-center space-x-6">
          {selectedStudent && (
            <span className="text-purple-700 text-sm md:text-base">
              編集中: {selectedStudent.name}
            </span>
          )}

          <span className="text-purple-700 text-sm md:text-base truncate max-w-xs">
            {currentUser.name}{' '}
            {currentUser.role === 'teacher'
              ? currentUser.isMaster
                ? '（マスター）'
                : '（教師）'
              : `（${getGradeName(currentUser.grade)}）`}
          </span>

          {currentUser.role === 'student' && (
            <button
              onClick={() => onOpenMessaging(masterTeacher)}
              className="relative text-purple-500 hover:text-yellow-500"
              aria-label="メッセージ"
            >
              <MessageSquare size={24} />
              {unreadCount > 0 && (
                <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-yellow-100 bg-yellow-600 rounded-full">
                  {unreadCount}
                </span>
              )}
            </button>
          )}

          {currentUser.role === 'teacher' && selectedStudent && (
            <button
              onClick={onOpenNotes}
              className="relative text-purple-500 hover:text-yellow-500"
              aria-label="メモ"
            >
              <FileText size={24} />
              {unreadNotesCount > 0 && (
                <span className="absolute -top-2 -right-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-yellow-100 bg-yellow-600 rounded-full">
                  {unreadNotesCount}
                </span>
              )}
            </button>
          )}

          {currentUser.role === 'teacher' && currentUser.isMaster && selectedStudent && (
            <button
              onClick={onExport}
              className="text-purple-500 hover:text-yellow-600 focus:outline-none"
              aria-label="課題をエクスポート"
            >
              <Download size={24} />
            </button>
          )}

          <button
            onClick={() => setIsLogoutConfirmOpen(true)}
            className="text-purple-500 hover:text-pink-500 focus:outline-none"
            aria-label="ログアウト"
          >
            <LogOut size={24} />
          </button>
        </div>
      </div>

      {isLogoutConfirmOpen && (
        <ConfirmDialog
          title="ログアウトの確認"
          message="本当にログアウトしますか？"
          onConfirm={() => {
            onLogout();
            setIsLogoutConfirmOpen(false);
          }}
          onCancel={() => setIsLogoutConfirmOpen(false)}
          confirmButtonText="ログアウト"
          cancelButtonText="キャンセル"
        />
      )}
    </nav>
  );
};

Navbar.propTypes = {
  currentUser: PropTypes.object.isRequired,
  selectedStudent: PropTypes.object,
  selectedTeacher: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
  onToggleSidebar: PropTypes.func.isRequired,
  onOpenMessaging: PropTypes.func.isRequired,
  onOpenNotes: PropTypes.func.isRequired,
  teachers: PropTypes.array.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default Navbar;