// QuizManagement.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ref, onValue, push, set, remove, update } from 'firebase/database';
import { database } from './config/firebase';
import { PlusCircle, Edit2, X, ChevronDown, ChevronUp, Upload } from 'lucide-react';
import QuizBulkUpload from './QuizBulkUpload';

const SUBJECTS = [
  { id: 'english', name: '英語' },
  { id: 'math', name: '数学' },
  { id: 'japanese', name: '国語' },
  { id: 'science', name: '理科' },
  { id: 'social', name: '社会' },
];

const ALL_SUBJECTS = [
  { id: 'all', name: '全教科' },
  ...SUBJECTS
];

const getSubjectName = (subjectId) => {
  const subject = SUBJECTS.find((s) => s.id === subjectId);
  return subject ? subject.name : subjectId;
};


const ContentEditModal = ({ 
  isOpen, 
  onClose, 
  mode, 
  newContent,
  setNewContent, 
  handleAddContent,
  handleUpdateContent,
  formErrors 
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose} />
      <div className="relative bg-white rounded-lg shadow-lg max-w-2xl w-full m-4 p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-purple-700">
            {mode === 'edit' ? 'コンテンツを編集' : '新しいコンテンツを追加'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label htmlFor="content-title" className="block text-sm font-medium text-purple-700 mb-1">
              コンテンツタイトル
            </label>
            <input
              type="text"
              id="content-title"
              name="title"
              value={newContent.title}
              onChange={(e) => setNewContent({ ...newContent, title: e.target.value })}
              placeholder="コンテンツのタイトルを入力"
              className="w-full p-2 border rounded"
            />
            {formErrors.title && <p className="text-red-600 text-sm">{formErrors.title}</p>}
          </div>

          <div>
            <label htmlFor="content-subject" className="block text-sm font-medium text-purple-700 mb-1">
              教科
            </label>
            <select
              id="content-subject"
              name="subject"
              value={newContent.subject}
              onChange={(e) => setNewContent({ ...newContent, subject: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="">教科を選択</option>
              {SUBJECTS.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            {formErrors.subject && <p className="text-red-600 text-sm">{formErrors.subject}</p>}
          </div>

          <div>
            <label htmlFor="randomize-quizzes" className="block text-sm font-medium text-purple-700 mb-1">
              出題順序
            </label>
            <select
              id="randomize-quizzes"
              name="randomizeQuizzes"
              value={newContent.randomizeQuizzes ? 'random' : 'fixed'}
              onChange={(e) => setNewContent({ ...newContent, randomizeQuizzes: e.target.value === 'random' })}
              className="w-full p-2 border rounded"
            >
              <option value="fixed">固定順</option>
              <option value="random">ランダム</option>
            </select>
          </div>

          <div>
            <label htmlFor="free-navigation" className="block text-sm font-medium text-purple-700 mb-1">
              問題の移動制限
            </label>
            <select
              id="free-navigation"
              name="freeNavigation"
              value={newContent.freeNavigation ? 'free' : 'restricted'}
              onChange={(e) => setNewContent({ ...newContent, freeNavigation: e.target.value === 'free' })}
              className="w-full p-2 border rounded"
            >
              <option value="restricted">順方向のみ（戻れない）</option>
              <option value="free">自由に行き来可能</option>
            </select>
          </div>

          {newContent.freeNavigation ? (
            <div>
              <label htmlFor="content-time-limit" className="block text-sm font-medium text-purple-700 mb-1">
                コンテンツ全体の制限時間（秒）
              </label>
              <input
                type="number"
                id="content-time-limit"
                name="timeLimitPerContent"
                value={newContent.timeLimitPerContent || ''}
                onChange={(e) => setNewContent({ ...newContent, timeLimitPerContent: e.target.value ? parseInt(e.target.value, 10) : 0 })}
                placeholder="例: 300 (5分)"
                className="w-full p-2 border rounded"
              />
            </div>
          ) : (
            <div>
              <label htmlFor="time-limit" className="block text-sm font-medium text-purple-700 mb-1">
                1問あたりの制限時間（秒）
              </label>
              <input
                type="number"
                id="time-limit"
                name="timeLimitPerQuestion"
                value={newContent.timeLimitPerQuestion || ''}
                onChange={(e) => setNewContent({ ...newContent, timeLimitPerQuestion: e.target.value ? parseInt(e.target.value, 10) : 0 })}
                placeholder="例: 30"
                className="w-full p-2 border rounded"
              />
            </div>
          )}

          <div>
            <label htmlFor="answer-reveal-mode" className="block text-sm font-medium text-purple-700 mb-1">
              解答表示モード
            </label>
            <select
              id="answer-reveal-mode"
              name="answerRevealMode"
              value={newContent.answerRevealMode}
              onChange={(e) => setNewContent({ ...newContent, answerRevealMode: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="immediate">各問題解答後に表示</option>
              <option value="end">最後にまとめて表示</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end gap-2 mt-6">
          <button
            onClick={mode === 'edit' ? handleUpdateContent : handleAddContent}
            className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
          >
            {mode === 'edit' ? '更新' : '追加'}
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
};

// QuizEditModal コンポーネント
const QuizEditModal = ({ 
  isOpen, 
  onClose, 
  mode, 
  quiz,
  contentSubject,
  handleSubmitQuiz,
  formErrors 
}) => {
  const [quizData, setQuizData] = useState({
    type: quiz?.type || 'multiple-choice',
    subject: quiz?.subject || '',
    question: quiz?.question || '',
    choices: quiz?.choices || ['', '', '', ''],
    answer: quiz?.answer || '',
    explanation: quiz?.explanation || '',
    englishSentence: quiz?.englishSentence || '',
    japaneseTranslation: quiz?.japaneseTranslation || '',
    leftItems: quiz?.leftItems || [],
    rightItems: quiz?.rightItems || [],
    correctMatches: quiz?.correctMatches || [],
    timestamp: quiz?.timestamp || Date.now()
  });

  // useEffect を追加して props の変更を監視
  useEffect(() => {
    setQuizData({
      type: quiz?.type || 'multiple-choice',
      subject: quiz?.subject || '',
      question: quiz?.question || '',
      choices: quiz?.choices || ['', '', '', ''],
      answer: quiz?.answer || '',
      explanation: quiz?.explanation || '',
      englishSentence: quiz?.englishSentence || '',
      japaneseTranslation: quiz?.japaneseTranslation || '',
      leftItems: quiz?.leftItems || [],
      rightItems: quiz?.rightItems || [],
      correctMatches: quiz?.correctMatches || [],
      timestamp: quiz?.timestamp || Date.now()
    });
  }, [quiz]);
  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose} />
      <div className="relative bg-white rounded-lg shadow-lg max-w-2xl w-full m-4 p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-purple-700">
            {mode === 'edit' ? 'クイズを編集' : '新しいクイズを追加'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label htmlFor="quiz-type" className="block text-sm font-medium text-purple-700 mb-1">
              クイズ形式
            </label>
            <select
              id="quiz-type"
              name="type"
              value={quizData.type}
              onChange={(e) => setQuizData({ ...quizData, type: e.target.value })}
              className="w-full p-2 border rounded"
              disabled={mode === 'edit'}
            >
              <option value="multiple-choice">ボタンを利用した四択問題</option>
              <option value="sentence-rearrangement">英文並び替え問題</option>
              <option value="true-false">正誤問題</option>
              <option value="fill-in-the-blank">穴埋め問題</option>
              <option value="matching">マッチング問題</option>
            </select>
            {formErrors.type && <p className="text-red-600 text-sm">{formErrors.type}</p>}
          </div>

          <div>
            <label htmlFor="quiz-subject" className="block text-sm font-medium text-purple-700 mb-1">
              教科
            </label>
            <select
              id="quiz-subject"
              name="subject"
              value={quizData.subject}
              onChange={(e) => setQuizData({ ...quizData, subject: e.target.value })}
              className="w-full p-2 border rounded"
            >
              <option value="">教科を選択</option>
              {SUBJECTS.map((subject) => (
                <option key={subject.id} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            {formErrors.subject && <p className="text-red-600 text-sm">{formErrors.subject}</p>}
          </div>

          {quizData.type === 'multiple-choice' && (
            <>
              <div>
                <label htmlFor="quiz-question-mc" className="block text-sm font-medium text-purple-700 mb-1">
                  問題文
                </label>
                <textarea
                  id="quiz-question-mc"
                  name="question"
                  value={quizData.question}
                  onChange={(e) => setQuizData({ ...quizData, question: e.target.value })}
                  placeholder="例: 2 + 2 は何ですか？"
                  className="w-full p-2 border rounded"
                  rows={3}
                />
                {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
              </div>

              <div>
                <label className="block text-sm font-medium text-purple-700 mb-1">選択肢</label>
                {[0, 1, 2, 3].map((index) => (
                  <input
                    key={index}
                    type="text"
                    value={quizData.choices[index] || ''}
                    onChange={(e) => {
                      const newChoices = [...quizData.choices];
                      newChoices[index] = e.target.value;
                      setQuizData({ ...quizData, choices: newChoices });
                    }}
                    placeholder={`選択肢 ${index + 1}`}
                    className="w-full p-2 border rounded mb-2"
                  />
                ))}
                {formErrors.choices && <p className="text-red-600 text-sm">{formErrors.choices}</p>}
              </div>

              <div>
                <label htmlFor="quiz-answer-mc" className="block text-sm font-medium text-purple-700 mb-1">
                  正解
                </label>
                <input
                  type="text"
                  id="quiz-answer-mc"
                  name="answer"
                  value={quizData.answer}
                  onChange={(e) => setQuizData({ ...quizData, answer: e.target.value })}
                  placeholder="正解を入力"
                  className="w-full p-2 border rounded"
                />
                {formErrors.answer && <p className="text-red-600 text-sm">{formErrors.answer}</p>}
              </div>
            </>
          )}

          {quizData.type === 'sentence-rearrangement' && (
            <>
              <div>
                <label htmlFor="quiz-english-sentence" className="block text-sm font-medium text-purple-700 mb-1">
                  英語の文
                </label>
                <textarea
                  id="quiz-english-sentence"
                  name="englishSentence"
                  value={quizData.englishSentence}
                  onChange={(e) => setQuizData({ ...quizData, englishSentence: e.target.value })}
                  placeholder="例: I am eating an apple."
                  className="w-full p-2 border rounded"
                  rows={3}
                />
                {formErrors.englishSentence && (
                  <p className="text-red-600 text-sm">{formErrors.englishSentence}</p>
                )}
              </div>

              <div>
                <label htmlFor="quiz-japanese-translation" className="block text-sm font-medium text-purple-700 mb-1">
                  日本語訳
                </label>
                <textarea
                  id="quiz-japanese-translation"
                  name="japaneseTranslation"
                  value={quizData.japaneseTranslation}
                  onChange={(e) => setQuizData({ ...quizData, japaneseTranslation: e.target.value })}
                  placeholder="例: 私はリンゴを食べています。"
                  className="w-full p-2 border rounded"
                  rows={3}
                />
                {formErrors.japaneseTranslation && (
                  <p className="text-red-600 text-sm">{formErrors.japaneseTranslation}</p>
                )}
              </div>
            </>
          )}
          
          
          
      {/* 正誤問題フォーム */}
      {quizData.type === 'true-false' && (
        <>
          <div>
            <label htmlFor="quiz-question-tf" className="block text-sm font-medium text-purple-700 mb-1">
              問題文
            </label>
            <textarea
              id="quiz-question-tf"
              name="question"
              value={quizData.question || ''}
              onChange={(e) => setQuizData({ ...quizData, question: e.target.value })}
              placeholder="例: 地球は平らですか？"
              className="w-full p-2 border rounded"
              rows={3}
            />
            {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium text-purple-700 mb-1">正誤</label>
            <div className="flex gap-4">
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="true-false-answer"
                  checked={quizData.answer === true}
                  onChange={() => setQuizData({ ...quizData, answer: true })}
                  className="form-radio"
                />
                正しい
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="true-false-answer"
                  checked={quizData.answer === false}
                  onChange={() => setQuizData({ ...quizData, answer: false })}
                  className="form-radio"
                />
                誤り
              </label>
            </div>
            {formErrors.answer && <p className="text-red-600 text-sm">{formErrors.answer}</p>}
          </div>
        </>
      )}

      {/* 穴埋め問題フォーム */}
      {quizData.type === 'fill-in-the-blank' && (
        <>
          <div>
            <label htmlFor="quiz-question-fitb" className="block text-sm font-medium text-purple-700 mb-1">
              問題文（空欄を含む）
            </label>
            <textarea
              id="quiz-question-fitb"
              name="question"
              value={quizData.question || ''}
              onChange={(e) => setQuizData({ ...quizData, question: e.target.value })}
              placeholder="例: 私は___を食べます。"
              className="w-full p-2 border rounded"
              rows={3}
            />
            {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
          </div>

          <div>
            <label htmlFor="quiz-answer-fitb" className="block text-sm font-medium text-purple-700 mb-1">
              正解
            </label>
            <input
              type="text"
              id="quiz-answer-fitb"
              name="answer"
              value={quizData.answer || ''}
              onChange={(e) => setQuizData({ ...quizData, answer: e.target.value })}
              placeholder="例: リンゴ"
              className="w-full p-2 border rounded"
            />
            {formErrors.answer && <p className="text-red-600 text-sm">{formErrors.answer}</p>}
          </div>
        </>
      )}

      {/* マッチング問題フォーム */}
      {quizData.type === 'matching' && (
        <>
          <div>
            <label htmlFor="quiz-question-matching" className="block text-sm font-medium text-purple-700 mb-1">
              問題文
            </label>
            <textarea
              id="quiz-question-matching"
              name="question"
              value={quizData.question || ''}
              onChange={(e) => setQuizData({ ...quizData, question: e.target.value })}
              placeholder="例: 以下の単語と意味をマッチさせなさい。"
              className="w-full p-2 border rounded"
              rows={3}
            />
            {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
          </div>

          <div className="mt-4 overflow-auto">
            <table className="min-w-full border">
              <thead>
                <tr>
                  <th className="border px-4 py-2">左側の項目</th>
                  <th className="border px-4 py-2">右側の項目</th>
                  <th className="border px-4 py-2">正しいマッチング (右側の項目の番号)</th>
                </tr>
              </thead>
              <tbody>
                {(quizData.leftItems || []).map((leftItem, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">
                      <input
                        type="text"
                        value={leftItem || ''}
                        onChange={(e) => {
                          const newLeftItems = [...(quizData.leftItems || [])];
                          newLeftItems[index] = e.target.value;
                          setQuizData({ ...quizData, leftItems: newLeftItems });
                        }}
                        className="w-full p-2 border rounded"
                        placeholder={`左側の項目 ${index + 1}`}
                      />
                    </td>
                    <td className="border px-4 py-2">
                      <input
                        type="text"
                        value={(quizData.rightItems || [])[index] || ''}
                        onChange={(e) => {
                          const newRightItems = [...(quizData.rightItems || [])];
                          newRightItems[index] = e.target.value;
                          setQuizData({ ...quizData, rightItems: newRightItems });
                        }}
                        className="w-full p-2 border rounded"
                        placeholder={`右側の項目 ${index + 1}`}
                      />
                    </td>
                    <td className="border px-4 py-2">
                      <input
                        type="number"
                        value={(quizData.correctMatches || [])[index] || ''}
                        onChange={(e) => {
                          const newCorrectMatches = [...(quizData.correctMatches || [])];
                          newCorrectMatches[index] = parseInt(e.target.value, 10);
                          setQuizData({ ...quizData, correctMatches: newCorrectMatches });
                        }}
                        className="w-full p-2 border rounded"
                        placeholder="0"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={() => {
                setQuizData({
                  ...quizData,
                  leftItems: [...(quizData.leftItems || []), ''],
                  rightItems: [...(quizData.rightItems || []), ''],
                  correctMatches: [...(quizData.correctMatches || []), '']
                });
              }}
              className="mt-2 text-purple-500 hover:text-purple-700 flex items-center gap-1"
              type="button"
            >
              <PlusCircle size={20} />
              項目を追加
            </button>
          </div>
        </>
      )}
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          

          {/* 共通の解説フィールド */}
          <div>
            <label htmlFor="quiz-explanation" className="block text-sm font-medium text-purple-700 mb-1">
              解説（任意）
            </label>
            <textarea
              id="quiz-explanation"
              name="explanation"
              value={quizData.explanation}
              onChange={(e) => setQuizData({ ...quizData, explanation: e.target.value })}
              placeholder="解説を入力してください（任意）"
              className="w-full p-2 border rounded"
              rows={3}
            />
          </div>
        </div>

        <div className="flex justify-end gap-2 mt-6">
          <button
            onClick={() => handleSubmitQuiz(quizData)}
            className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
          >
            {mode === 'edit' ? '更新' : '追加'}
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
};






const QuizManagement = ({ currentUser }) => {
  const [contents, setContents] = useState([]);
  const [isAddingContent, setIsAddingContent] = useState(false);
  const [newContent, setNewContent] = useState({
    title: '',
    subject: '',
    quizzes: [],
    randomizeQuizzes: false,
    timeLimitPerQuestion: 0,
    freeNavigation: false,
    timeLimitPerContent: 0, // 全体制限時間用
    answerRevealMode: 'immediate', // 追加: 解答表示モード
  });
  const [formErrors, setFormErrors] = useState({});
  const [editingContentId, setEditingContentId] = useState(null);
  const [editingQuiz, setEditingQuiz] = useState(null);
  const [addingQuiz, setAddingQuiz] = useState(null);
  const [expandedContents, setExpandedContents] = useState({});

  const [activeSubject, setActiveSubject] = useState('all');

  const isMaster = currentUser.isMaster;
  
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  
  

  useEffect(() => {
    const contentsRef = ref(database, 'contents');
    const unsubscribe = onValue(contentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const contentList = Object.entries(data).map(([id, content]) => ({
          id,
          ...content,
          quizzes: content.quizzes
            ? Object.entries(content.quizzes).map(([qid, quiz]) => ({
                id: qid,
                ...quiz,
              }))
            : [],
        }));
        setContents(contentList);
      } else {
        setContents([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const filteredContents = activeSubject === 'all'
    ? contents
    : contents.filter((content) => content.subject === activeSubject);

  const handleAddContent = async () => {
    if (!isMaster) {
      alert('コンテンツの追加権限がありません。');
      return;
    }

    const errors = {};
    if (!newContent.title.trim()) errors.title = 'タイトルを入力してください。';
    if (!newContent.subject) errors.subject = '教科を選択してください。';

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    try {
      const contentRef = push(ref(database, 'contents'));

      const contentData = {
        title: newContent.title,
        subject: newContent.subject,
        quizzes: {},
        randomizeQuizzes: newContent.randomizeQuizzes,
        freeNavigation: newContent.freeNavigation || false,
        answerRevealMode: newContent.answerRevealMode, // 追加: 解答表示モード
      };

      if (newContent.freeNavigation) {
        // 全体制限時間を使用
        contentData.timeLimitPerContent = newContent.timeLimitPerContent || 0;
        contentData.timeLimitPerQuestion = 0;
      } else {
        // 問題ごとの制限時間を使用
        contentData.timeLimitPerQuestion = newContent.timeLimitPerQuestion || 0;
        contentData.timeLimitPerContent = 0;
      }

      await set(contentRef, contentData);

      setNewContent({
        title: '',
        subject: '',
        quizzes: [],
        randomizeQuizzes: false,
        timeLimitPerQuestion: 0,
        freeNavigation: false,
        timeLimitPerContent: 0,
        answerRevealMode: 'immediate', // デフォルトに戻す
      });
      setIsAddingContent(false);
      setFormErrors({});
    } catch (error) {
      console.error('コンテンツの追加に失敗しました:', error);
      alert('コンテンツの追加に失敗しました。再試行してください。');
    }
  };

const handleAddQuiz = (contentId, subject) => {
  if (!isMaster) {
    alert('クイズの追加権限がありません。');
    return;
  }
  setAddingQuiz({
    contentId,
    quiz: {
      type: 'multiple-choice',
      subject: subject,
      question: '',
      choices: ['', '', '', ''],  // 必ず配列を初期化
      answer: '',
      explanation: '',
      englishSentence: '',
      japaneseTranslation: '',
      leftItems: [],
      rightItems: [],
      correctMatches: [],
      timestamp: Date.now(),
    }
  });
  setQuizModalOpen(true);
};















  // コンテンツ編集ボタンのクリックハンドラ
const handleEditContent = (content) => {
  if (!isMaster) {
    alert('コンテンツの編集権限がありません。');
    return;
  }
  setEditingContentId(content.id);
  setNewContent({
    title: content.title,
    subject: content.subject,
    quizzes: [],
    randomizeQuizzes: content.randomizeQuizzes || false,
    freeNavigation: content.freeNavigation || false,
    timeLimitPerQuestion: 0,
    timeLimitPerContent: 0,
    answerRevealMode: content.answerRevealMode || 'immediate'
  });

  if (content.freeNavigation) {
    setNewContent(prev => ({
      ...prev,
      timeLimitPerContent: content.timeLimitPerContent || 0
    }));
  } else {
    setNewContent(prev => ({
      ...prev,
      timeLimitPerQuestion: content.timeLimitPerQuestion || 0
    }));
  }
  setContentModalOpen(true);
};

  const handleUpdateContent = async () => {
    if (!isMaster) {
      alert('コンテンツの更新権限がありません。');
      return;
    }

    const errors = {};
    if (!newContent.title.trim()) errors.title = 'タイトルを入力してください。';
    if (!newContent.subject) errors.subject = '教科を選択してください。';

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    try {
      const contentRef = ref(database, `contents/${editingContentId}`);

      const updatedData = {
        title: newContent.title,
        subject: newContent.subject,
        randomizeQuizzes: newContent.randomizeQuizzes,
        freeNavigation: newContent.freeNavigation || false,
        answerRevealMode: newContent.answerRevealMode, // 追加: 解答表示モード
      };

      if (newContent.freeNavigation) {
        updatedData.timeLimitPerContent = newContent.timeLimitPerContent || 0;
        updatedData.timeLimitPerQuestion = 0;
      } else {
        updatedData.timeLimitPerQuestion = newContent.timeLimitPerQuestion || 0;
        updatedData.timeLimitPerContent = 0;
      }

      await update(contentRef, updatedData);
      setEditingContentId(null);
      setNewContent({
        title: '',
        subject: '',
        quizzes: [],
        randomizeQuizzes: false,
        timeLimitPerQuestion: 0,
        freeNavigation: false,
        timeLimitPerContent: 0,
        answerRevealMode: 'immediate', // デフォルトに戻す
      });
      setFormErrors({});
    } catch (error) {
      console.error('コンテンツの更新に失敗しました:', error);
      alert('コンテンツの更新に失敗しました。再試行してください。');
    }
  };

  const handleDeleteContent = async (contentId) => {
    if (!isMaster) {
      alert('コンテンツの削除権限がありません。');
      return;
    }

    if (window.confirm('このコンテンツを削除してもよろしいですか？')) {
      try {
        await remove(ref(database, `contents/${contentId}`));
        setExpandedContents((prev) => {
          const updated = { ...prev };
          delete updated[contentId];
          return updated;
        });
      } catch (error) {
        console.error('コンテンツの削除に失敗しました:', error);
        alert('コンテンツの削除に失敗しました。再試行してください。');
      }
    }
  };

  // クイズ編集ボタンのクリックハンドラ
const handleEditQuiz = (contentId, quiz) => {
  if (!isMaster) {
    alert('クイズの編集権限がありません。');
    return;
  }
  setEditingQuiz({
    contentId,
    quiz: {
      id: quiz.id,
      type: quiz.type,
      subject: quiz.subject,
      question: quiz.question || '',
      choices: quiz.choices || ['', '', '', ''],
      answer: quiz.answer || '',
      explanation: quiz.explanation || '',
      englishSentence: quiz.englishSentence || '',
      japaneseTranslation: quiz.japaneseTranslation || '',
      leftItems: quiz.leftItems || [],
      rightItems: quiz.rightItems || [],
      correctMatches: quiz.correctMatches || [],
      timestamp: quiz.timestamp || Date.now()
    }
  });
  setQuizModalOpen(true);
};

  const handleSubmitQuiz = async () => {
    if (!isMaster) {
      alert('クイズの保存権限がありません。');
      return;
    }

    const currentQuizData = addingQuiz ? addingQuiz.quiz : editingQuiz.quiz;
    const contentId = addingQuiz ? addingQuiz.contentId : editingQuiz.contentId;
    const errors = {};

    if (!currentQuizData.subject) errors.subject = '教科を選択してください。';

    if (currentQuizData.type === 'multiple-choice') {
      if (!currentQuizData.question.trim()) errors.question = '問題文を入力してください。';
      if (!currentQuizData.choices || currentQuizData.choices.some((choice) => !choice.trim())) {
        errors.choices = 'すべての選択肢を入力してください。';
      }
      if (!currentQuizData.answer.trim()) errors.answer = '正解を入力してください。';
    } else if (currentQuizData.type === 'sentence-rearrangement') {
      if (!currentQuizData.englishSentence?.trim()) errors.englishSentence = '英語の文を入力してください。';
      if (!currentQuizData.japaneseTranslation?.trim()) errors.japaneseTranslation = '日本語訳を入力してください。';
    } else if (currentQuizData.type === 'true-false') {
      if (!currentQuizData.question.trim()) errors.question = '問題文を入力してください。';
      if (typeof currentQuizData.answer !== 'boolean') errors.answer = '正誤を選択してください。';
    } else if (currentQuizData.type === 'fill-in-the-blank') {
      if (!currentQuizData.question.trim()) errors.question = '問題文を入力してください。';
      if (!currentQuizData.answer.trim()) errors.answer = '正解を入力してください。';
    } else if (currentQuizData.type === 'matching') {
      if (!currentQuizData.question.trim()) errors.question = '問題文を入力してください。';
      if (!currentQuizData.leftItems || currentQuizData.leftItems.some((item) => !item.trim())) {
        errors.leftItems = 'すべての左側の項目を入力してください。';
      }
      if (!currentQuizData.rightItems || currentQuizData.rightItems.some((item) => !item.trim())) {
        errors.rightItems = 'すべての右側の項目を入力してください。';
      }
      if (
        !currentQuizData.correctMatches ||
        currentQuizData.correctMatches.length !== currentQuizData.leftItems.length
      ) {
        errors.correctMatches = '正しいマッチングを入力してください。';
      }
    } else {
      errors.type = '不明なクイズ形式です。';
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    try {
      if (addingQuiz) {
        const quizzesRef = ref(database, `contents/${contentId}/quizzes`);
        const newQuizRef = push(quizzesRef);
        await set(newQuizRef, {
          ...currentQuizData,
          timestamp: Date.now(),
        });
        alert('クイズが正常に追加されました。');
        setAddingQuiz(null);
      } else if (editingQuiz) {
        const quizRef = ref(database, `contents/${contentId}/quizzes/${currentQuizData.id}`);
        await update(quizRef, {
          ...currentQuizData,
          timestamp: Date.now(),
        });
        alert('クイズが正常に更新されました。');
        setEditingQuiz(null);
      }
      setFormErrors({});
    } catch (error) {
      console.error('クイズの保存に失敗しました:', error);
      alert('クイズの保存に失敗しました。再試行してください。');
    }
  };

  const handleDeleteQuiz = async (contentId, quizId) => {
    if (!isMaster) {
      alert('クイズの削除権限がありません。');
      return;
    }

    if (window.confirm('このクイズを削除してもよろしいですか？')) {
      try {
        await remove(ref(database, `contents/${contentId}/quizzes/${quizId}`));
      } catch (error) {
        console.error('クイズの削除に失敗しました:', error);
        alert('クイズの削除に失敗しました。再試行してください。');
      }
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold text-purple-700 mb-6">クイズ管理</h2>
      
     {/* マスター教師用の一括アップロードボタン */}
      {currentUser.isMaster && (
        <div className="mb-6">
          <button
            onClick={() => setShowBulkUpload(true)}
            className="w-full py-4 border-2 border-dashed border-purple-300 rounded-lg hover:border-purple-500 hover:text-purple-500 flex items-center justify-center gap-2 transition-colors duration-200"
          >
            <Upload size={20} />
            エクセルファイルから一括登録
          </button>
        </div>
      )}

      {/* 一括アップロードモーダル */}
      {showBulkUpload && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50" />
          <div className="relative bg-white rounded-lg shadow-lg max-w-2xl w-full m-4">
            <QuizBulkUpload onClose={() => setShowBulkUpload(false)} />
          </div>
        </div>
      )}
      
      
      

      <div className="flex flex-wrap gap-2 mb-6">
        {ALL_SUBJECTS.map(subject => (
          <button
            key={subject.id}
            onClick={() => setActiveSubject(subject.id)}
            className={
              `px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200
              ${activeSubject === subject.id
                ? 'bg-purple-500 text-white'
                : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
              }`
            }
            aria-label={`${subject.name}を選択`}
          >
            {subject.name}
          </button>
        ))}
      </div>

      <div className="space-y-4">
        {filteredContents.map((content) => (
          <div key={content.id} className="p-4 bg-pink-50 rounded shadow">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h2 className="text-lg font-semibold text-purple-800">{content.title}</h2>
                <p className="text-sm text-gray-600">教科: {getSubjectName(content.subject)}</p>
                <p className="text-sm text-gray-600">出題順序: {content.randomizeQuizzes ? 'ランダム' : '固定'}</p>
                {content.freeNavigation ? (
                  content.timeLimitPerContent > 0 && (
                    <p className="text-sm text-gray-600">全体制限時間: {content.timeLimitPerContent}秒</p>
                  )
                ) : (
                  content.timeLimitPerQuestion > 0 && (
                    <p className="text-sm text-gray-600">1問あたりの制限時間: {content.timeLimitPerQuestion}秒</p>
                  )
                )}
                <p className="text-sm text-gray-600">問題移動: {content.freeNavigation ? '自由' : '一方向'}</p>
                <p className="text-sm text-gray-600">解答表示モード: {content.answerRevealMode === 'immediate' ? '即時表示' : '最後まとめ表示'}</p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setExpandedContents((prev) => ({
                    ...prev,
                    [content.id]: !prev[content.id],
                  }))}
                  className="text-purple-700 hover:text-purple-900 focus:outline-none"
                  aria-label={expandedContents[content.id] ? 'クイズ一覧を非表示にする' : 'クイズ一覧を表示する'}
                >
                  {expandedContents[content.id] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </button>

                <button
                  onClick={() => handleEditContent(content)}
                  className="text-blue-500 hover:text-blue-700"
                  aria-label={`コンテンツ${content.id}を編集`}
                >
                  <Edit2 size={20} />
                </button>

                <button
                  onClick={() => handleDeleteContent(content.id)}
                  className="text-red-500 hover:text-red-700"
                  aria-label={`コンテンツ${content.id}を削除`}
                >
                  <X size={20} />
                </button>
              </div>
            </div>

            {expandedContents[content.id] && (
              <>
                <div className="space-y-2 mb-4">
                  {content.quizzes.length > 0 ? (
                    content.quizzes.map((quiz) => (
                      <div key={quiz.id} className="p-2 bg-white rounded shadow flex justify-between items-center">
                        <div>
                          <h3 className="text-md font-medium text-purple-700">
                            {quiz.type === 'multiple-choice' ? quiz.question
                              : quiz.type === 'sentence-rearrangement' ? quiz.englishSentence
                                : quiz.type === 'true-false' ? quiz.question
                                  : quiz.type === 'fill-in-the-blank' ? quiz.question
                                    : quiz.type === 'matching' ? quiz.question
                                      : ''}
                          </h3>
                          <p className="text-sm text-gray-600">教科: {getSubjectName(quiz.subject)}</p>
                        </div>
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => handleEditQuiz(content.id, quiz)}
                            className="text-blue-500 hover:text-blue-700"
                            aria-label={`クイズ${quiz.id}を編集`}
                          >
                            <Edit2 size={20} />
                          </button>
                          <button
                            onClick={() => handleDeleteQuiz(content.id, quiz.id)}
                            className="text-red-500 hover:text-red-700"
                            aria-label={`クイズ${quiz.id}を削除`}
                          >
                            <X size={20} />
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">このコンテンツにはクイズが含まれていません。</p>
                  )}
                </div>

                <div className="mt-4">
                  <button
                    onClick={() => handleAddQuiz(content.id, content.subject)}
                    className="w-full py-2 bg-teal-100 text-teal-700 rounded hover:bg-teal-200 flex items-center justify-center gap-2"
                    aria-label="新しいクイズを追加"
                  >
                    <PlusCircle size={20} />
                    新しいクイズを追加
                  </button>
                </div>
              </>
            )}
          </div>
        ))}

        {/* 修正箇所: 三項演算子に : とelse部分を追加 */}
        {(isAddingContent || editingContentId) ? (
          <div className="mt-6 p-4 bg-blue-50 rounded shadow">
            <h3 className="text-xl font-semibold text-purple-700 mb-4">
              {editingContentId ? 'コンテンツを編集' : '新しいコンテンツを追加'}
            </h3>
            <div className="space-y-4">
              <div>
                <label htmlFor="content-title" className="block text-sm font-medium text-purple-700 mb-1">
                  コンテンツタイトル
                </label>
                <input
                  type="text"
                  id="content-title"
                  name="title"
                  value={newContent.title}
                  onChange={(e) => setNewContent({ ...newContent, title: e.target.value })}
                  placeholder="コンテンツのタイトルを入力"
                  className="w-full p-2 border rounded"
                />
                {formErrors.title && <p className="text-red-600 text-sm">{formErrors.title}</p>}
              </div>

              <div>
                <label htmlFor="content-subject" className="block text-sm font-medium text-purple-700 mb-1">
                  教科
                </label>
                <select
                  id="content-subject"
                  name="subject"
                  value={newContent.subject}
                  onChange={(e) => setNewContent({ ...newContent, subject: e.target.value })}
                  className="w-full p-2 border rounded"
                >
                  <option value="">教科を選択</option>
                  {SUBJECTS.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                      {subject.name}
                    </option>
                  ))}
                </select>
                {formErrors.subject && <p className="text-red-600 text-sm">{formErrors.subject}</p>}
              </div>

              <div>
                <label htmlFor="randomize-quizzes" className="block text-sm font-medium text-purple-700 mb-1">
                  出題順序
                </label>
                <select
                  id="randomize-quizzes"
                  name="randomizeQuizzes"
                  value={newContent.randomizeQuizzes ? 'random' : 'fixed'}
                  onChange={(e) => setNewContent({ ...newContent, randomizeQuizzes: e.target.value === 'random' })}
                  className="w-full p-2 border rounded"
                >
                  <option value="fixed">固定順</option>
                  <option value="random">ランダム</option>
                </select>
              </div>

              <div>
                <label htmlFor="free-navigation" className="block text-sm font-medium text-purple-700 mb-1">
                  問題の移動制限
                </label>
                <select
                  id="free-navigation"
                  name="freeNavigation"
                  value={newContent.freeNavigation ? 'free' : 'restricted'}
                  onChange={(e) => setNewContent({ ...newContent, freeNavigation: e.target.value === 'free' })}
                  className="w-full p-2 border rounded"
                >
                  <option value="restricted">順方向のみ（戻れない）</option>
                  <option value="free">自由に行き来可能</option>
                </select>
              </div>

              {newContent.freeNavigation ? (
                <div>
                  <label htmlFor="content-time-limit" className="block text-sm font-medium text-purple-700 mb-1">
                    コンテンツ全体の制限時間（秒）
                  </label>
                  <input
                    type="number"
                    id="content-time-limit"
                    name="timeLimitPerContent"
                    value={newContent.timeLimitPerContent || ''}
                    onChange={(e) => setNewContent({ ...newContent, timeLimitPerContent: e.target.value ? parseInt(e.target.value, 10) : 0 })}
                    placeholder="例: 300 (5分)"
                    className="w-full p-2 border rounded"
                  />
                </div>
              ) : (
                <div>
                  <label htmlFor="time-limit" className="block text-sm font-medium text-purple-700 mb-1">
                    1問あたりの制限時間（秒）
                  </label>
                  <input
                    type="number"
                    id="time-limit"
                    name="timeLimitPerQuestion"
                    value={newContent.timeLimitPerQuestion || ''}
                    onChange={(e) => setNewContent({ ...newContent, timeLimitPerQuestion: e.target.value ? parseInt(e.target.value, 10) : 0 })}
                    placeholder="例: 30"
                    className="w-full p-2 border rounded"
                  />
                </div>
              )}

              <div>
                <label htmlFor="answer-reveal-mode" className="block text-sm font-medium text-purple-700 mb-1">
                  解答表示モード
                </label>
                <select
                  id="answer-reveal-mode"
                  name="answerRevealMode"
                  value={newContent.answerRevealMode}
                  onChange={(e) => setNewContent({ ...newContent, answerRevealMode: e.target.value })}
                  className="w-full p-2 border rounded"
                >
                  <option value="immediate">各問題解答後に表示</option>
                  <option value="end">最後にまとめて表示</option>
                </select>
              </div>

              <div className="flex justify-end gap-2">
                {editingContentId ? (
                  <>
                    <button
                      onClick={handleUpdateContent}
                      className="px-4 py-2 bg-teal-400 text-white rounded hover:bg-teal-500"
                    >
                      更新
                    </button>
                    <button
                      onClick={() => {
                        setEditingContentId(null);
                        setNewContent({
                          title: '',
                          subject: '',
                          quizzes: [],
                          randomizeQuizzes: false,
                          timeLimitPerQuestion: 0,
                          freeNavigation: false,
                          timeLimitPerContent: 0,
                          answerRevealMode: 'immediate',
                        });
                        setFormErrors({});
                      }}
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                    >
                      キャンセル
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={handleAddContent}
                      className="px-4 py-2 bg-teal-400 text-white rounded hover:bg-teal-500"
                    >
                      追加
                    </button>
                    <button
                      onClick={() => {
                        setIsAddingContent(false);
                        setNewContent({
                          title: '',
                          subject: '',
                          quizzes: [],
                          randomizeQuizzes: false,
                          timeLimitPerQuestion: 0,
                          freeNavigation: false,
                          timeLimitPerContent: 0,
                          answerRevealMode: 'immediate',
                        });
                        setFormErrors({});
                      }}
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                    >
                      キャンセル
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : ( // ここに : と else部分を追加することでエラーを解消
          <div className="mt-6">
            <button
              onClick={() => setIsAddingContent(true)}
              className="w-full py-4 bg-pink-100 text-pink-700 rounded-lg hover:bg-pink-200 flex items-center justify-center gap-2"
            >
              <PlusCircle size={20} />
              新しいコンテンツを追加
            </button>
          </div>
        )}

            {(editingQuiz || addingQuiz) && (
              <div className="mt-6 p-4 bg-blue-50 rounded shadow">
                <h3 className="text-xl font-semibold text-purple-700 mb-4">
                  {editingQuiz ? 'クイズを編集' : '新しいクイズを追加'}
                </h3>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="quiz-type" className="block text-sm font-medium text-purple-700 mb-1">
                      クイズ形式
                    </label>
                    <select
                      id="quiz-type"
                      name="type"
                      value={(editingQuiz || addingQuiz).quiz.type}
                      onChange={(e) => {
                        const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, type: e.target.value };
                        if (editingQuiz) {
                          setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                        } else {
                          setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                        }
                      }}
                      className="w-full p-2 border rounded"
                      disabled={editingQuiz ? true : false}
                    >
                      <option value="multiple-choice">ボタンを利用した四択問題</option>
                      <option value="sentence-rearrangement">英文並び替え問題</option>
                      <option value="true-false">正誤問題</option>
                      <option value="fill-in-the-blank">穴埋め問題</option>
                      <option value="matching">マッチング問題</option>
                    </select>
                    {formErrors.type && <p className="text-red-600 text-sm">{formErrors.type}</p>}
                  </div>

                  <div>
                    <label htmlFor="quiz-subject" className="block text-sm font-medium text-purple-700 mb-1">
                      教科
                    </label>
                    <select
                      id="quiz-subject"
                      name="subject"
                      value={(editingQuiz || addingQuiz).quiz.subject}
                      onChange={(e) => {
                        const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, subject: e.target.value };
                        if (editingQuiz) {
                          setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                        } else {
                          setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                        }
                      }}
                      className="w-full p-2 border rounded"
                    >
                      <option value="">教科を選択</option>
                      {SUBJECTS.map((subject) => (
                        <option key={subject.id} value={subject.id}>
                          {subject.name}
                        </option>
                      ))}
                    </select>
                    {formErrors.subject && <p className="text-red-600 text-sm">{formErrors.subject}</p>}
                  </div>

                  {/* multiple-choice用フォーム */}
                  {(editingQuiz || addingQuiz).quiz.type === 'multiple-choice' && (
                    <>
                      <div>
                        <label htmlFor="quiz-question-mc" className="block text-sm font-medium text-purple-700 mb-1">
                          問題文
                        </label>
                        <textarea
                          id="quiz-question-mc"
                          name="question"
                          value={(editingQuiz || addingQuiz).quiz.question}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, question: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: 2 + 2 は何ですか？"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                        {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-purple-700 mb-1">
                          選択肢
                        </label>
                        {[0, 1, 2, 3].map((index) => (
                          <input
                            key={index}
                            type="text"
                            name={`choice-${index}`}
                            value={(editingQuiz || addingQuiz).quiz.choices[index] || ''}
                            onChange={(e) => {
                              const updatedChoices = [...(editingQuiz || addingQuiz).quiz.choices];
                              updatedChoices[index] = e.target.value;
                              const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, choices: updatedChoices };
                              if (editingQuiz) {
                                setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                              } else {
                                setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                              }
                            }}
                            placeholder={`選択肢 ${index + 1}`}
                            className="w-full p-2 border rounded mb-2"
                          />
                        ))}
                        {formErrors.choices && <p className="text-red-600 text-sm">{formErrors.choices}</p>}
                      </div>

                      <div>
                        <label htmlFor="quiz-answer-mc" className="block text-sm font-medium text-purple-700 mb-1">
                          正解
                        </label>
                        <input
                          type="text"
                          id="quiz-answer-mc"
                          name="answer"
                          value={(editingQuiz || addingQuiz).quiz.answer}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, answer: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: 4"
                          className="w-full p-2 border rounded"
                        />
                        {formErrors.answer && <p className="text-red-600 text-sm">{formErrors.answer}</p>}
                      </div>

                      <div>
                        <label htmlFor="quiz-explanation-mc" className="block text-sm font-medium text-purple-700 mb-1">
                          解説（任意）
                        </label>
                        <textarea
                          id="quiz-explanation-mc"
                          name="explanation"
                          value={(editingQuiz || addingQuiz).quiz.explanation || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, explanation: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="解説を入力してください（任意）"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                      </div>
                    </>
                  )}

                  {/* sentence-rearrangement用フォーム */}
                  {(editingQuiz || addingQuiz).quiz.type === 'sentence-rearrangement' && (
                    <>
                      <div>
                        <label htmlFor="quiz-english-sentence" className="block text-sm font-medium text-purple-700 mb-1">
                          英語の文
                        </label>
                        <textarea
                          id="quiz-english-sentence"
                          name="englishSentence"
                          value={(editingQuiz || addingQuiz).quiz.englishSentence || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, englishSentence: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: I am eating an apple."
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                        {formErrors.englishSentence && <p className="text-red-600 text-sm">{formErrors.englishSentence}</p>}
                      </div>

                      <div>
                        <label htmlFor="quiz-japanese-translation" className="block text-sm font-medium text-purple-700 mb-1">
                          日本語訳
                        </label>
                        <textarea
                          id="quiz-japanese-translation"
                          name="japaneseTranslation"
                          value={(editingQuiz || addingQuiz).quiz.japaneseTranslation || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, japaneseTranslation: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: 私はリンゴを食べています。"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                        {formErrors.japaneseTranslation && <p className="text-red-600 text-sm">{formErrors.japaneseTranslation}</p>}
                      </div>

                      <div>
                        <label htmlFor="quiz-explanation-sr" className="block text-sm font-medium text-purple-700 mb-1">
                          解説（任意）
                        </label>
                        <textarea
                          id="quiz-explanation-sr"
                          name="explanation"
                          value={(editingQuiz || addingQuiz).quiz.explanation || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, explanation: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="解説を入力してください（任意）"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                      </div>
                    </>
                  )}

                  {/* true-false用フォーム */}
                  {(editingQuiz || addingQuiz).quiz.type === 'true-false' && (
                    <>
                      <div>
                        <label htmlFor="quiz-question-tf" className="block text-sm font-medium text-purple-700 mb-1">
                          問題文
                        </label>
                        <textarea
                          id="quiz-question-tf"
                          name="question"
                          value={(editingQuiz || addingQuiz).quiz.question || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, question: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: 地球は平らですか？"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                        {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-purple-700 mb-1">正誤</label>
                        <div className="flex gap-4">
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="true-false-answer"
                              value="true"
                              checked={(editingQuiz || addingQuiz).quiz.answer === true}
                              onChange={() => {
                                const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, answer: true };
                                if (editingQuiz) {
                                  setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                                } else {
                                  setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                                }
                              }}
                              className="form-radio"
                            />
                            正しい
                          </label>
                          <label className="flex items-center gap-2">
                            <input
                              type="radio"
                              name="true-false-answer"
                              value="false"
                              checked={(editingQuiz || addingQuiz).quiz.answer === false}
                              onChange={() => {
                                const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, answer: false };
                                if (editingQuiz) {
                                  setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                                } else {
                                  setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                                }
                              }}
                              className="form-radio"
                            />
                            誤り
                          </label>
                        </div>
                        {formErrors.answer && <p className="text-red-600 text-sm">{formErrors.answer}</p>}
                      </div>

                      <div>
                        <label htmlFor="quiz-explanation-tf" className="block text-sm font-medium text-purple-700 mb-1">
                          解説（任意）
                        </label>
                        <textarea
                          id="quiz-explanation-tf"
                          name="explanation"
                          value={(editingQuiz || addingQuiz).quiz.explanation || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, explanation: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="解説を入力してください（任意）"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                      </div>
                    </>
                  )}

                  {/* fill-in-the-blank用フォーム */}
                  {(editingQuiz || addingQuiz).quiz.type === 'fill-in-the-blank' && (
                    <>
                      <div>
                        <label htmlFor="quiz-question-fitb" className="block text-sm font-medium text-purple-700 mb-1">
                          問題文（空欄を含む）
                        </label>
                        <textarea
                          id="quiz-question-fitb"
                          name="question"
                          value={(editingQuiz || addingQuiz).quiz.question || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, question: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: 私は___を食べます。"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                        {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
                      </div>

                      <div>
                        <label htmlFor="quiz-answer-fitb" className="block text-sm font-medium text-purple-700 mb-1">
                          正解
                        </label>
                        <input
                          type="text"
                          id="quiz-answer-fitb"
                          name="answer"
                          value={(editingQuiz || addingQuiz).quiz.answer || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, answer: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: リンゴ"
                          className="w-full p-2 border rounded"
                        />
                        {formErrors.answer && <p className="text-red-600 text-sm">{formErrors.answer}</p>}
                      </div>

                      <div>
                        <label htmlFor="quiz-explanation-fitb" className="block text-sm font-medium text-purple-700 mb-1">
                          解説（任意）
                        </label>
                        <textarea
                          id="quiz-explanation-fitb"
                          name="explanation"
                          value={(editingQuiz || addingQuiz).quiz.explanation || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, explanation: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="解説を入力してください（任意）"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                      </div>
                    </>
                  )}

                  {/* matching用フォーム */}
                  {(editingQuiz || addingQuiz).quiz.type === 'matching' && (
                    <>
                      <div>
                        <label htmlFor="quiz-question-matching" className="block text-sm font-medium text-purple-700 mb-1">
                          問題文
                        </label>
                        <textarea
                          id="quiz-question-matching"
                          name="question"
                          value={(editingQuiz || addingQuiz).quiz.question || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, question: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="例: 以下の単語と意味をマッチさせなさい。"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                        {formErrors.question && <p className="text-red-600 text-sm">{formErrors.question}</p>}
                      </div>

                      <div className="mt-4 overflow-auto">
                        <table className="min-w-full border">
                          <thead>
                            <tr>
                              <th className="border px-4 py-2">左側の項目</th>
                              <th className="border px-4 py-2">右側の項目</th>
                              <th className="border px-4 py-2">正しいマッチング (右側の項目の番号)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {((editingQuiz || addingQuiz).quiz.leftItems || []).map((leftItem, index) => (
                              <tr key={index}>
                                <td className="border px-4 py-2">
                                  <input
                                    type="text"
                                    name={`left-item-${index}`}
                                    value={leftItem}
                                    onChange={(e) => {
                                      const updatedLeftItems = [...(editingQuiz || addingQuiz).quiz.leftItems];
                                      updatedLeftItems[index] = e.target.value;
                                      const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, leftItems: updatedLeftItems };
                                      if (editingQuiz) {
                                        setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                                      } else {
                                        setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                                      }
                                    }}
                                    placeholder={`左側の項目 ${index + 1}`}
                                    className="w-full p-2 border rounded"
                                  />
                                </td>
                                <td className="border px-4 py-2">
                                  <input
                                    type="text"
                                    name={`right-item-${index}`}
                                    value={((editingQuiz || addingQuiz).quiz.rightItems || [])[index] || ''}
                                    onChange={(e) => {
                                      const updatedRightItems = [...(editingQuiz || addingQuiz).quiz.rightItems];
                                      updatedRightItems[index] = e.target.value;
                                      const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, rightItems: updatedRightItems };
                                      if (editingQuiz) {
                                        setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                                      } else {
                                        setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                                      }
                                    }}
                                    placeholder={`右側の項目 ${index + 1}`}
                                    className="w-full p-2 border rounded"
                                  />
                                </td>
                                <td className="border px-4 py-2">
                                  <input
                                    type="number"
                                    name={`match-index-${index}`}
                                    value={((editingQuiz || addingQuiz).quiz.correctMatches || [])[index] || ''}
                                    onChange={(e) => {
                                      const updatedCorrectMatches = [...(editingQuiz || addingQuiz).quiz.correctMatches];
                                      updatedCorrectMatches[index] = parseInt(e.target.value, 10);
                                      const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, correctMatches: updatedCorrectMatches };
                                      if (editingQuiz) {
                                        setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                                      } else {
                                        setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                                      }
                                    }}
                                    placeholder="0"
                                    className="w-full p-2 border rounded"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <button
                          onClick={() => {
                            const updatedLeftItems = [...((editingQuiz || addingQuiz).quiz.leftItems || []), ''];
                            const updatedRightItems = [...((editingQuiz || addingQuiz).quiz.rightItems || []), ''];
                            const updatedCorrectMatches = [...((editingQuiz || addingQuiz).quiz.correctMatches || []), ''];
                            const updatedQuiz = {
                              ...(editingQuiz || addingQuiz).quiz,
                              leftItems: updatedLeftItems,
                              rightItems: updatedRightItems,
                              correctMatches: updatedCorrectMatches,
                            };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          className="mt-2 text-purple-500 hover:text-purple-700 flex items-center gap-1"
                          aria-label="項目を追加"
                        >
                          <PlusCircle size={20} />
                          項目を追加
                        </button>
                        {formErrors.leftItems && <p className="text-red-600 text-sm">{formErrors.leftItems}</p>}
                        {formErrors.rightItems && <p className="text-red-600 text-sm">{formErrors.rightItems}</p>}
                        {formErrors.correctMatches && <p className="text-red-600 text-sm">{formErrors.correctMatches}</p>}
                      </div>

                      <div className="mt-4">
                        <label htmlFor="quiz-explanation-matching" className="block text-sm font-medium text-purple-700 mb-1">
                          解説（任意）
                        </label>
                        <textarea
                          id="quiz-explanation-matching"
                          name="explanation"
                          value={(editingQuiz || addingQuiz).quiz.explanation || ''}
                          onChange={(e) => {
                            const updatedQuiz = { ...(editingQuiz || addingQuiz).quiz, explanation: e.target.value };
                            if (editingQuiz) {
                              setEditingQuiz({ ...editingQuiz, quiz: updatedQuiz });
                            } else {
                              setAddingQuiz({ ...addingQuiz, quiz: updatedQuiz });
                            }
                          }}
                          placeholder="解説を入力してください（任意）"
                          className="w-full p-2 border rounded"
                          rows={3}
                        />
                      </div>
                    </>
                  )}

                  <div className="flex justify-end gap-2">
                    <button
                      onClick={handleSubmitQuiz}
                      className="px-4 py-2 bg-teal-400 text-white rounded hover:bg-teal-500"
                      aria-label={editingQuiz ? 'クイズを更新' : 'クイズを追加'}
                    >
                      {editingQuiz ? '更新' : '追加'}
                    </button>
                    <button
                      onClick={() => {
                        if (editingQuiz) {
                          setEditingQuiz(null);
                        } else {
                          setAddingQuiz(null);
                        }
                        setFormErrors({});
                      }}
                      className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                      aria-label="キャンセル"
                    >
                      キャンセル
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          
          
          
          
          
          
          
          
          
              <ContentEditModal 
      isOpen={contentModalOpen}
      onClose={() => {
        setContentModalOpen(false);
        setEditingContentId(null);
        setNewContent({
          title: '',
          subject: '',
          quizzes: [],
          randomizeQuizzes: false,
          timeLimitPerQuestion: 0,
          freeNavigation: false,
          timeLimitPerContent: 0,
          answerRevealMode: 'immediate',
        });
        setFormErrors({});
      }}
      mode={editingContentId ? 'edit' : 'add'}
      newContent={newContent}
      setNewContent={setNewContent}
      handleAddContent={handleAddContent}
      handleUpdateContent={handleUpdateContent}
      formErrors={formErrors}
    />

    <QuizEditModal 
      isOpen={quizModalOpen}
      onClose={() => {
        setQuizModalOpen(false);
        setEditingQuiz(null);
        setAddingQuiz(null);
        setFormErrors({});
      }}
      mode={editingQuiz ? 'edit' : 'add'}
      quiz={editingQuiz ? editingQuiz.quiz : addingQuiz ? addingQuiz.quiz : {}}
      contentSubject={contents.find(c => c.id === (editingQuiz?.contentId || addingQuiz?.contentId))?.subject}
      handleSubmitQuiz={handleSubmitQuiz}
      formErrors={formErrors}
    />
          
          
          
          
          
          
          
          
        </div>
      );
    };

    QuizManagement.propTypes = {
      currentUser: PropTypes.shape({
        role: PropTypes.string.isRequired,
        isMaster: PropTypes.bool,
      }).isRequired,
    };

    export default QuizManagement;
