// EditTaskForm.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { PlusCircle } from 'lucide-react';
import { ref, push, set } from 'firebase/database';
import { database } from './config/firebase'; // Firebaseのパスを調整してください

const EditTaskForm = ({ task, onSave, onCancel }) => {
  const [title, setTitle] = useState(task.title || '');
  const [items, setItems] = useState(
    Object.values(task.items || {}).map(item => ({
      ...item,
      id: item.id || uuidv4(), // id がない場合は新規生成
    })).sort((a, b) => (a.order || 0) - (b.order || 0))
  );

  const handleAddItem = () => {
    setItems(prev => [
      ...prev,
      {
        id: uuidv4(),
        text: '',
        dueDate: '',
        testDate: '',
        testScore: '',
        checked: false,
        order: prev.length, // 現在の配列長を基にorderを設定
      }
    ]);
  };

  const handleRemoveItem = index => {
    setItems(prev => {
      const newItems = prev.filter((_, i) => i !== index);
      // 削除後にorderを再設定
      return newItems.map((item, idx) => ({ ...item, order: idx }));
    });
  };

  const handleSubmit = () => {
    const validItems = items.filter(item => item.text.trim());
    if (!title.trim() || validItems.length === 0) {
      alert('タイトルと少なくとも一つの項目を入力してください。');
      return;
    }
    const updatedTask = {
      ...task,
      title,
      items: validItems.reduce((acc, item, index) => {
        acc[item.id] = {
          ...item,
          order: index, // 現在の配列インデックスに基づいてorderを設定
        };
        return acc;
      }, {})
    };
    onSave(updatedTask);
  };

  return (
    <div className="p-4 mb-6 bg-yellow-50 rounded-lg shadow">
      {/* タイトルセクション */}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1 mb-4">
          <label htmlFor="task-title" className="font-medium text-purple-700">
            課題タイトル
            <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            id="task-title" // 一意のIDを追加
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="w-full p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
            placeholder="例: 数学の宿題"
            aria-label="課題タイトル入力"
          />
        </div>

        {/* チェック項目セクション */}
        <div className="flex flex-col gap-4">
          {items.map((item, index) => (
            <div key={item.id} className="flex flex-col gap-3">
              {/* 各項目の編集フィールド */}
              <div className="flex flex-col gap-1">
                <label htmlFor={`add-text-${item.id}`} className="sr-only">
                  チェック項目 {index + 1}
                </label>
                <textarea
                  id={`add-text-${item.id}`} // 一意のIDを追加
                  value={item.text}
                  onChange={(e) => {
                    const newItems = [...items];
                    newItems[index].text = e.target.value;
                    setItems(newItems);
                  }}
                  placeholder="例: 数学の問題を解く"
                  className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                  rows={2}
                  required
                  aria-label={`チェック項目 ${index + 1} 入力`}
                />
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* 宿題期限フィールド */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor={`dueDate-${task.id}-${item.id}`}
                    className="font-medium text-purple-700"
                  >
                    宿題期限
                    <span className="text-purple-500 ml-1">(任意)</span>
                  </label>
                  <input
                    id={`dueDate-${task.id}-${item.id}`}
                    type="date"
                    value={item.dueDate}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].dueDate = e.target.value;
                      setItems(newItems);
                    }}
                    className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                    placeholder="例: 2024-12-31"
                  />
                  {!item.dueDate && (
                    <span className="text-purple-500 text-sm">
                      宿題期限が未設定です。
                    </span>
                  )}
                </div>

                {/* 確認テスト日時フィールド */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor={`testDate-${task.id}-${item.id}`}
                    className="font-medium text-purple-700"
                  >
                    確認テスト日時
                    <span className="text-purple-500 ml-1">(任意)</span>
                  </label>
                  <input
                    id={`testDate-${task.id}-${item.id}`}
                    type="date"
                    value={item.testDate}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].testDate = e.target.value;
                      setItems(newItems);
                    }}
                    className="p-2 border border-purple-300 rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300"
                    placeholder="例: 2024-12-15"
                  />
                  {!item.testDate && (
                    <span className="text-purple-500 text-sm">
                      確認テスト日時が未設定です。
                    </span>
                  )}
                </div>

                {/* スコアフィールド */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor={`testScore-${task.id}-${item.id}`}
                    className="font-medium text-purple-700"
                  >
                    スコア
                    <span className="text-purple-500 ml-1">(0〜100)</span>
                  </label>
                  <input
                    id={`testScore-${task.id}-${item.id}`}
                    type="number"
                    value={item.testScore}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (Number(value) >= 0 && Number(value) <= 100)) {
                        const newItems = [...items];
                        newItems[index].testScore = value;
                        setItems(newItems);
                      }
                    }}
                    placeholder="例: 85"
                    className={`w-full p-2 border rounded mb-1 focus:outline-none focus:ring-2 focus:ring-purple-300 ${
                      (item.testScore < 0 || item.testScore > 100) && 'border-red-500'
                    }`}
                    min="0"
                    max="100"
                    aria-label="スコア入力"
                  />
                  {(item.testScore < 0 || item.testScore > 100) && (
                    <span className="text-red-500 text-sm">
                      スコアは0から100の範囲で入力してください。
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        
        {/* 項目の追加ボタン */}
        <div className="flex justify-start mt-4">
          <button
            type="button"
            onClick={handleAddItem}
            className="text-purple-500 hover:text-purple-600 flex items-center gap-1"
            aria-label="項目を追加ボタン"
          >
            <PlusCircle size={20} />
            項目を追加
          </button>
        </div>
        
        {/* 保存とキャンセルボタン */}
        <div className="flex justify-between mt-6">
          <button
            type="button"
            onClick={handleSubmit}
            className="bg-purple-300 text-purple-800 px-5 py-2 rounded hover:bg-purple-400"
            aria-label="課題保存ボタン"
          >
            保存
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="bg-purple-100 text-purple-700 px-5 py-2 rounded hover:bg-purple-200"
            aria-label="課題編集キャンセルボタン"
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
};

EditTaskForm.propTypes = {
  task: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditTaskForm;
