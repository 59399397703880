import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  
  const handleLogin = () => {
    onLogin(username, password);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-yellow-50">
      <div className="bg-white p-6 rounded shadow w-full max-w-md">
        <h2 className="text-2xl font-semibold mb-6 text-center">ログイン</h2>
        <input
          type="text"
          value={username}
          onChange={e => setUsername(e.target.value)}
          placeholder="ユーザー名"
          className="w-full p-3 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-purple-300"
          onKeyDown={handleKeyDown}
          aria-label="ユーザー名入力"
        />
        <input
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="パスワード"
          className="w-full p-3 border rounded mb-6 focus:outline-none focus:ring-2 focus:ring-purple-300"
          onKeyDown={handleKeyDown}
          aria-label="パスワード入力"
        />
        <button
          onClick={handleLogin}
          className="w-full bg-purple-300 text-purple-800 py-3 rounded hover:bg-purple-400 transition-colors duration-200"
          aria-label="ログインボタン"
        >
          ログイン
        </button>
      </div>
    </div>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default Login;