import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';

const SUBJECTS = [
  { id: 'english', name: '英語' },
  { id: 'math', name: '数学' },
  { id: 'japanese', name: '国語' },
  { id: 'science', name: '理科' },
  { id: 'social', name: '社会' }
];

const GRADES = [
  { id: 'shougaku1', name: '小1' },
  { id: 'shougaku2', name: '小2' },
  { id: 'shougaku3', name: '小3' },
  { id: 'shougaku4', name: '小4' },
  { id: 'shougaku5', name: '小5' },
  { id: 'shougaku6', name: '小6' },
  { id: 'chuugaku1', name: '中1' },
  { id: 'chuugaku2', name: '中2' },
  { id: 'chuugaku3', name: '中3' },
  { id: 'koukou1', name: '高1' },
  { id: 'koukou2', name: '高2' },
  { id: 'koukou3', name: '高3' }
];

const BulkTaskImport = ({
  students,
  onImport,
  selectedStudent,
  selectedSubject,
  tasksByStudentAndSubject,
}) => {
  const [importing, setImporting] = useState(false);
  const [error, setError] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const getGradeId = (gradeName) => {
    const grade = GRADES.find((g) => g.name === gradeName.trim());
    return grade ? grade.id : null;
  };

  const parseCSV = (text) => {
    const rows = text.split('\n');
    const headers = rows[0].split(',').map((header) => header.trim());

    return rows.slice(1)
      .filter((row) => row.trim())
      .map((row) => {
        const values = row.split(',').map((value) => value.trim());
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index];
          return obj;
        }, {});
      });
  };

  const getSubjectId = (subjectName) => {
    const subjectMap = {
      '数学': 'math',
      '英語': 'english',
      '国語': 'japanese',
      '理科': 'science',
      '社会': 'social',
    };
    return subjectMap[subjectName.trim()];
  };

  const getStudentId = (studentName, gradeName) => {
    const gradeId = getGradeId(gradeName);
    if (!gradeId) return null;

    const student = students.find(
      (s) => s.name.trim() === studentName.trim() && s.grade === gradeId
    );
    return student ? student.id : null;
  };

  const validateData = (data) => {
    const errors = [];
    const requiredFields = ['生徒名', '学年', '教科', '課題名', 'チェック項目'];

    data.forEach((row, index) => {
      requiredFields.forEach((field) => {
        if (!row[field] || row[field].toString().trim() === '') {
          errors.push(`行 ${index + 2}: ${field}が未入力です`);
        }
      });

      if (row['教科'] && !getSubjectId(row['教科'])) {
        errors.push(`行 ${index + 2}: 無効な教科名です: ${row['教科']}`);
      }

      if (row['生徒名'] && row['学年']) {
        const studentId = getStudentId(row['生徒名'], row['学年']);
        if (!studentId) {
          errors.push(`行 ${index + 2}: 生徒が見つかりません: ${row['生徒名']}（${row['学年']}）`);
        }
      }
    });

    return errors;
  };

  const processData = (data) => {
    const tasksByStudentAndSubject = {};
    let taskOrder = 0;

    const taskGroups = {};
    data.forEach((row) => {
      const title = row['課題名'].trim();
      if (!taskGroups[title]) {
        taskGroups[title] = [];
      }
      taskGroups[title].push(row);
    });

    const orderedTitles = data
      .map((row) => row['課題名'].trim())
      .filter((title, index, self) => self.indexOf(title) === index);

    orderedTitles.forEach((title) => {
      const rows = taskGroups[title];
      let itemOrder = 0;

      rows.forEach((row) => {
        const studentId = getStudentId(row['生徒名'], row['学年']);
        const subjectId = getSubjectId(row['教科']);
        const key = `${studentId}-${subjectId}`;

        if (!tasksByStudentAndSubject[key]) {
          tasksByStudentAndSubject[key] = { tasks: {} };
        }

        const existingTask = Object.values(tasksByStudentAndSubject[key].tasks).find(
          (task) => task.title === title
        );

        if (existingTask) {
          const itemId = uuidv4();
          existingTask.items[itemId] = {
            id: itemId,
            text: row['チェック項目'].trim(),
            order: itemOrder++,
            dueDate: row['課題期限'] || '',
            testDate: row['確認テスト日時'] || '',
            testScore: row['確認テストスコア'] || '',
            checked: row['チェックボックス']?.toString().toLowerCase() === 'true',
          };
        } else {
          const taskId = uuidv4();
          const itemId = uuidv4();
          tasksByStudentAndSubject[key].tasks[taskId] = {
            id: taskId,
            title: title,
            order: taskOrder++,
            items: {
              [itemId]: {
                id: itemId,
                text: row['チェック項目'].trim(),
                order: itemOrder++,
                dueDate: row['課題期限'] || '',
                testDate: row['確認テスト日時'] || '',
                testScore: row['確認テストスコア'] || '',
                checked: row['チェックボックス']?.toString().toLowerCase() === 'true',
              },
            },
          };
        }
      });
    });

    return tasksByStudentAndSubject;
  };

  const parseFile = (file, callback) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      let jsonData = [];

      if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
        jsonData = parseCSV(data);
      } else if (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      ) {
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
      }

      callback(jsonData);
    };

    if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };

  const handleFileUpload = (file) => {
    if (!file) return;

    const allowedTypes = [
      'text/csv',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ];
    const isValidType =
      allowedTypes.includes(file.type) ||
      ['.csv', '.xlsx', '.xls'].some((ext) => file.name.endsWith(ext));
    if (!isValidType) {
      setError('CSVまたはエクセルファイルのみアップロード可能です');
      return;
    }

    setImporting(true);
    setError(null);
    setPreview(null);

    parseFile(file, (data) => {
      try {
        const validationErrors = validateData(data);
        if (validationErrors.length > 0) {
          setError(validationErrors.join('\n'));
          return;
        }

        const processedData = processData(data);
        setPreview({
          totalTasks: Object.values(processedData).reduce(
            (sum, { tasks }) => sum + Object.keys(tasks).length,
            0
          ),
          totalItems: Object.values(processedData).reduce(
            (sum, { tasks }) =>
              sum +
              Object.values(tasks).reduce(
                (itemSum, task) => itemSum + Object.keys(task.items).length,
                0
              ),
            0
          ),
        });

        onImport(processedData);
      } catch (error) {
        console.error('Error processing file:', error);
        setError('ファイルの処理中にエラーが発生しました');
      } finally {
        setImporting(false);
      }
    });
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  return (
    <div
      className={`p-4 bg-white rounded-lg shadow ${
        isDragging ? 'border-yellow-300 bg-yellow-100' : 'border-purple-200'
      }`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">課題の一括登録</h3>
        <p className="text-sm text-gray-600 mb-4">
          CSVまたはエクセルファイルから課題を一括登録できます。
          必要な列: 生徒名, 学年, 教科, 課題名, チェック項目, 課題期限, 確認テスト日時, 確認テストスコア, チェックボックス
        </p>

        <label className="flex flex-col items-center p-4 border-2 border-dashed cursor-pointer rounded-lg transition-colors bg-purple-100 border-purple-300 hover:border-purple-400">
          <Upload className="w-8 h-8 mb-2 text-purple-500" />
          <span className="text-sm text-purple-700">
            CSVまたはエクセルファイルを選択またはドラッグ＆ドロップ
          </span>
          <input
            type="file"
            accept=".csv, .xlsx, .xls"
            onChange={handleFileInputChange}
            className="hidden"
            disabled={importing}
          />
        </label>
      </div>

      {importing && (
        <div className="text-center text-yellow-600">インポート中...</div>
      )}

      {error && (
        <div className="text-yellow-600 mt-4">
          <p className="whitespace-pre-line">{error}</p>
        </div>
      )}

      {preview && (
        <div className="text-purple-600 mt-4">
          <p>{`${preview.totalTasks}件の課題（合計${preview.totalItems}項目）の登録準備が完了しました。`}</p>
        </div>
      )}
    </div>
  );
};

BulkTaskImport.propTypes = {
  students: PropTypes.array.isRequired,
  onImport: PropTypes.func.isRequired,
  selectedStudent: PropTypes.object.isRequired,
  selectedSubject: PropTypes.object.isRequired,
  tasksByStudentAndSubject: PropTypes.object.isRequired,
};

export default BulkTaskImport;