import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CheckSquare, Square } from 'lucide-react';

const SubjectSummaryTabs = ({ subjects, selectedSubject, onSelectSubject }) => (
  <div className="flex flex-wrap gap-2 mb-6">
    {subjects.map(subject => (
      <button
        key={subject.id}
        onClick={() => onSelectSubject(subject)}
        className={`px-4 py-2 rounded-full font-medium transition-colors duration-200 ${
          selectedSubject.id === subject.id
            ? 'bg-purple-300 text-purple-800 shadow'
            : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
        }`}
      >
        {subject.name}
      </button>
    ))}
  </div>
);

const TaskSummaryItem = ({ 
  item, 
  isTeacher, 
  onCheck, 
  onTeacherCheck 
}) => {
  // 教師の確認ボタンコンポーネント
  const TeacherCheckButtons = () => (
    <div className="flex flex-col items-center">
      <span className="text-sm text-purple-700 mb-1">先生確認</span>
      <div className="flex gap-2">
        <button
          onClick={() => onTeacherCheck('maru')}
          className={`w-8 h-8 rounded-full flex items-center justify-center font-bold ${
            item.teacherCheck === 'maru'
              ? 'bg-yellow-300 text-yellow-800'
              : 'bg-purple-200 hover:bg-purple-300'
          }`}
          aria-label="丸をつける"
        >
          ○
        </button>
        <button
          onClick={() => onTeacherCheck('triangle')}
          className={`w-8 h-8 rounded-full flex items-center justify-center font-bold ${
            item.teacherCheck === 'triangle'
              ? 'bg-purple-300 text-purple-800'
              : 'bg-purple-200 hover:bg-purple-300'
          }`}
          aria-label="三角をつける"
        >
          △
        </button>
        <button
          onClick={() => onTeacherCheck('batsu')}
          className={`w-8 h-8 rounded-full flex items-center justify-center font-bold ${
            item.teacherCheck === 'batsu'
              ? 'bg-pink-300 text-pink-800'
              : 'bg-purple-200 hover:bg-purple-300'
          }`}
          aria-label="バツをつける"
        >
          ×
        </button>
      </div>
    </div>
  );

  // 教師の確認マークの表示（生徒用）
  const TeacherCheckDisplay = () => {
    const getTeacherCheckMark = (status) => {
      switch (status) {
        case 'maru':
          return <span className="text-yellow-600 font-bold text-xl">○</span>;
        case 'triangle':
          return <span className="text-purple-600 font-bold text-xl">△</span>;
        case 'batsu':
          return <span className="text-pink-600 font-bold text-xl">×</span>;
        default:
          return <span className="text-gray-400 text-sm">未</span>;
      }
    };

    return (
      <div className="flex flex-col items-center">
        <span className="text-sm text-purple-700 mb-1">先生確認</span>
        <div className="w-8 h-8 flex items-center justify-center">
          {getTeacherCheckMark(item.teacherCheck)}
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-between p-3 bg-white rounded-lg shadow mb-2">
      <div className="flex items-center space-x-4 flex-1">
        {/* チェックボックス - 教師と生徒の両方が操作可能 */}
        <div className="flex items-center gap-2">
          <button
            onClick={onCheck}
            className="text-purple-500 hover:text-purple-700 transition-colors"
            aria-label={item.checked ? 'チェックを外す' : 'チェックする'}
          >
            {item.checked ? <CheckSquare size={20} /> : <Square size={20} />}
          </button>
          <span className={`${
            item.checked ? 'line-through text-purple-400' : 'text-purple-800'
          }`}>
            {item.text}
          </span>
        </div>
      </div>
      
      {/* 教師の場合は編集ボタン、そうでない場合は表示のみ */}
      <div className="flex-shrink-0 ml-4">
        {isTeacher ? <TeacherCheckButtons /> : <TeacherCheckDisplay />}
      </div>
    </div>
  );
};

const TaskSummaryCard = ({ 
  task, 
  isTeacher,
  onCheckItem,
  onTeacherCheckItem 
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  // 完了率の計算
  const calculateCompletionRate = () => {
    const items = Object.values(task.items || {});
    if (items.length === 0) return 0;
    
    const completedItems = items.filter(item => item.checked);
    return Math.round((completedItems.length / items.length) * 100);
  };

  // 合格率（○の割合）の計算
  const calculateSuccessRate = () => {
    const items = Object.values(task.items || {});
    if (items.length === 0) return 0;
    
    const successItems = items.filter(item => item.teacherCheck === 'maru');
    return Math.round((successItems.length / items.length) * 100);
  };

  const completionRate = calculateCompletionRate();
  const successRate = calculateSuccessRate();

  // 項目の総数を取得
  const totalItems = Object.keys(task.items || {}).length;

  return (
    <div className="bg-yellow-50 rounded-lg shadow p-4 mb-4">
      {/* ヘッダー部分 */}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="w-full flex justify-between items-center gap-4 cursor-pointer hover:bg-yellow-100 p-2 rounded transition-colors duration-200"
        aria-expanded={!isCollapsed}
        aria-controls={`task-content-${task.id}`}
      >
        <div className="flex items-center gap-2">
          <div className="transform transition-transform duration-200" style={{
            transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)'
          }}>
            ▼
          </div>
          <h3 className="font-semibold text-lg text-purple-700">{task.title}</h3>
          <span className="text-sm text-purple-600">
            ({totalItems}件)
          </span>
        </div>
        <div className="flex gap-4 text-sm">
          <div className={`flex items-center ${
            completionRate === 100 ? 'text-yellow-600 font-bold' : 'text-purple-600'
          }`}>
            <span>完了率: {completionRate}%</span>
          </div>
          <div className={`flex items-center ${
            successRate === 100 ? 'text-yellow-600 font-bold' : 'text-purple-600'
          }`}>
            <span>合格率: {successRate}%</span>
          </div>
        </div>
      </button>

      {/* 折りたたみ可能なコンテンツ部分 */}
      <div
        id={`task-content-${task.id}`}
        className={`space-y-2 transition-all duration-300 ${
          isCollapsed ? 'h-0 opacity-0 invisible' : 'h-auto opacity-100 visible mt-4'
        }`}
      >
        {Object.entries(task.items || {})
          .sort(([, a], [, b]) => (a.order || 0) - (b.order || 0))
          .map(([itemId, item]) => (
            <TaskSummaryItem
              key={itemId}
              item={item}
              isTeacher={isTeacher}
              onCheck={() => onCheckItem(task.id, itemId)}
              onTeacherCheck={(status) => onTeacherCheckItem(task.id, itemId, status)}
            />
          ))}
      </div>
    </div>
  );
};

const SchoolTaskSummary = ({ 
  tasks, 
  selectedStudent, 
  subjects,
  isTeacher,
  onCheckItem,
  onTeacherCheckItem 
}) => {
  const [selectedSubject, setSelectedSubject] = useState(subjects[0]);

  const getTasksForCurrentSubject = () => {
    if (!selectedStudent) return [];

    const key = `${selectedStudent.id}-${selectedSubject.id}`;
    const currentTasks = tasks[key];

    if (!currentTasks) return [];

    return Object.entries(currentTasks)
      .map(([id, task]) => ({ ...task, id }))
      .sort((a, b) => (a.order || 0) - (b.order || 0));
  };

  // 科目変更時にローディング状態をリセット
  useEffect(() => {
    const key = selectedStudent ? `${selectedStudent.id}-${selectedSubject.id}` : null;
    if (key && tasks.hasOwnProperty(key)) {
      // データが既に存在する場合の処理
      console.log(`Tasks found for ${key}:`, tasks[key]);
    }
  }, [selectedSubject, selectedStudent, tasks]);

  const currentTasks = getTasksForCurrentSubject();

  return (
    <div className="space-y-6">
      <SubjectSummaryTabs
        subjects={subjects}
        selectedSubject={selectedSubject}
        onSelectSubject={setSelectedSubject}
      />
      
      <div className="space-y-4">
        {currentTasks.length > 0 ? (
          currentTasks.map((task) => (
            <TaskSummaryCard
              key={task.id}
              task={task}
              isTeacher={isTeacher}
              onCheckItem={(taskId, itemId) => onCheckItem(selectedSubject.id, taskId, itemId)}
              onTeacherCheckItem={(taskId, itemId, status) => 
                onTeacherCheckItem(selectedSubject.id, taskId, itemId, status)
              }
            />
          ))
        ) : (
          <div className="text-center p-6 bg-white rounded-lg shadow">
            <p className="text-purple-600">
              {isTeacher
                ? '選択された生徒の課題はまだ登録されていません。'
                : 'まだ課題が登録されていません。'}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

// PropTypes の定義
SchoolTaskSummary.propTypes = {
  tasks: PropTypes.object.isRequired,
  selectedStudent: PropTypes.object,
  subjects: PropTypes.array.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  onTeacherCheckItem: PropTypes.func.isRequired,
};

SubjectSummaryTabs.propTypes = {
  subjects: PropTypes.array.isRequired,
  selectedSubject: PropTypes.object.isRequired,
  onSelectSubject: PropTypes.func.isRequired,
};

TaskSummaryCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    items: PropTypes.object,
  }).isRequired,
  isTeacher: PropTypes.bool.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  onTeacherCheckItem: PropTypes.func.isRequired,
};

TaskSummaryItem.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    teacherCheck: PropTypes.string,
  }).isRequired,
  isTeacher: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  onTeacherCheck: PropTypes.func.isRequired,
};

export default SchoolTaskSummary;