// components/Tasks/TaskItem.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';
import {
  CheckSquare,
  Square,
  Edit2,
  PlusCircle,
  X,
  LogOut,
  CheckSquare as CheckSquareIcon,
  Square as SquareIcon,
  Calendar,
  Edit,
  Upload,
  MessageSquare,
  Edit3,
  FileText
} from 'lucide-react';
import ConfirmDialog from './ConfirmDialog'; // 確認ダイアログのパスを調整してください

// アラート関連コンポーネントが必要な場合はインポート
// import Alert from '../Alert/Alert';
// import AlertDescription from '../Alert/AlertDescription';

// 先生確認用ボタンコンポーネント
const TeacherCheckButton = ({ onTeacherCheck, item }) => {
  const handleCheck = (status) => {
    if (item.teacherCheck === status) {
      onTeacherCheck(null);
    } else {
      onTeacherCheck(status);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <span className="font-medium text-purple-700 text-base">先生確認</span>
      <div className="flex gap-2 mt-1">
        <button
          onClick={() => handleCheck('maru')}
          className={`w-8 h-8 rounded-full flex items-center justify-center font-bold text-2xl ${
            item.teacherCheck === 'maru'
              ? 'bg-yellow-300 text-yellow-800'
              : 'bg-purple-200 hover:bg-purple-300'
          }`}
          aria-label={`丸チェック ${item.text}`}
        >
          ○
        </button>
        <button
          onClick={() => handleCheck('triangle')}
          className={`w-8 h-8 rounded-full flex items-center justify-center font-bold text-2xl ${
            item.teacherCheck === 'triangle'
              ? 'bg-purple-300 text-purple-800'
              : 'bg-purple-200 hover:bg-purple-300'
          }`}
          aria-label={`△チェック ${item.text}`}
        >
          △
        </button>
        <button
          onClick={() => handleCheck('batsu')}
          className={`w-8 h-8 rounded-full flex items-center justify-center font-bold text-2xl ${
            item.teacherCheck === 'batsu'
              ? 'bg-pink-300 text-pink-800'
              : 'bg-purple-200 hover:bg-purple-300'
          }`}
          aria-label={`バツチェック ${item.text}`}
        >
          ×
        </button>
      </div>
    </div>
  );
};

TeacherCheckButton.propTypes = {
  onTeacherCheck: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

// 先生確認の表示コンポーネント
const TeacherCheckDisplay = ({ item }) => {
  const checkMap = {
    maru: '○',
    triangle: '△',
    batsu: '×',
  };

  return (
    <div className="flex flex-col items-center">
      <span className="font-medium text-purple-700 text-base">先生確認</span>
      <span
        className={`${
          item.teacherCheck
            ? `mt-1 font-bold text-3xl ${
                item.teacherCheck === 'maru'
                  ? 'text-yellow-600'
                  : item.teacherCheck === 'triangle'
                  ? 'text-purple-600'
                  : 'text-pink-600'
              }`
            : `mt-2.5 text-sm font-bold italic text-purple-600`
        }`}
      >
        {item.teacherCheck ? checkMap[item.teacherCheck] : '未確認'}
      </span>
    </div>
  );
};

TeacherCheckDisplay.propTypes = {
  item: PropTypes.object.isRequired,
};

// 合否判定ボタン（教師用）
const PassFailButton = ({ onPassFail, item }) => {
  const handlePassClick = () => {
    onPassFail('pass');
  };

  const handleFailClick = () => {
    onPassFail('fail');
  };

  return (
    <div className="flex gap-2 mt-1">
      <button
        onClick={handlePassClick}
        className={`px-4 py-2 rounded text-sm font-bold ${
          item.passStatus === 'pass'
            ? 'bg-yellow-300 text-yellow-800'
            : 'bg-purple-200 text-purple-600 hover:bg-purple-300'
        }`}
        aria-label={`合格 ${item.text}`}
      >
        合格
      </button>
      <button
        onClick={handleFailClick}
        className={`px-4 py-2 rounded text-sm font-bold ${
          item.passStatus === 'fail'
            ? 'bg-pink-300 text-pink-800'
            : 'bg-purple-200 text-purple-600 hover:bg-purple-300'
        }`}
        aria-label={`不合格 ${item.text}`}
      >
        不合格
      </button>
    </div>
  );
};

PassFailButton.propTypes = {
  onPassFail: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

// 合否判定の表示（生徒用）
const PassFailDisplay = ({ item }) => {
  if (!item.passStatus)
    return <span className="text-purple-600 text-sm mt-1">未判定</span>;

  return (
    <span
      className={`font-bold text-lg mt-1 ${
        item.passStatus === 'pass' ? 'text-yellow-600' : 'text-pink-600'
      }`}
    >
      {item.passStatus === 'pass' ? '合格' : '不合格'}
    </span>
  );
};

PassFailDisplay.propTypes = {
  item: PropTypes.object.isRequired,
};

// メイン TaskItem コンポーネント
const TaskItem = ({
  item,
  onCheck,
  onTeacherCheck,
  isTeacher,
  onPassFail,
  onUpdateItem,
  onDeleteItem,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedItem, setEditedItem] = useState({ ...item });
  const [confirmDialog, setConfirmDialog] = useState(null);

  const linkifyOptions = {
    target: '_blank',
    rel: 'noopener noreferrer',
    className: 'text-purple-500 underline',
  };

  // 編集フォームのハンドル
  const handleSave = () => {
    // バリデーション
    if (!editedItem.text || !editedItem.text.trim()) {
      alert('チェック項目のテキストを入力してください。');
      return;
    }

    // 更新ハンドラーを呼び出す
    onUpdateItem({ ...editedItem, id: item.id });

    // 編集モードを終了
    setIsEditing(false);
  };

  const handleCancel = () => {
    // 編集内容をリセット
    setEditedItem({ ...item });
    setIsEditing(false);
  };

  // 削除ボタンのハンドル
  const handleDelete = () => {
    setConfirmDialog({
      title: 'チェック項目の削除',
      message: 'このチェック項目を削除してもよろしいですか？',
      onConfirm: () => {
        onDeleteItem(item.id);
        setConfirmDialog(null);
      },
      onCancel: () => setConfirmDialog(null),
    });
  };

  return (
    <div className="mb-4 p-4 bg-white rounded-lg shadow">
      {isEditing ? (
        // 編集フォームの表示
        <div className="flex flex-col gap-4">
          {/* チェック項目の編集 */}
          <div className="flex flex-col gap-1">
            <label
              htmlFor={`edit-text-${item.id}`}
              className="font-medium text-purple-700"
            >
              チェック項目
              <span className="text-purple-700 ml-1">*</span>
            </label>
            <textarea
              id={`edit-text-${item.id}`}
              value={editedItem.text || ''}
              onChange={(e) => setEditedItem({ ...editedItem, text: e.target.value })}
              placeholder="例: 数学の問題を解く"
              className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
              rows={2}
              required
            />
          </div>

          {/* 宿題期限、確認テスト日時、スコアの編集を同じ行に並べる */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {/* 宿題期限の編集 */}
            <div className="flex flex-col gap-1">
              <label
                htmlFor={`edit-dueDate-${item.id}`}
                className="font-medium text-purple-700"
              >
                宿題期限
              </label>
              <input
                id={`edit-dueDate-${item.id}`}
                type="date"
                value={editedItem.dueDate || ''}
                onChange={(e) => setEditedItem({ ...editedItem, dueDate: e.target.value })}
                className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
                placeholder="例: 2024-12-31"
              />
              {!editedItem.dueDate && (
                <span className="text-purple-600 text-sm">
                  宿題期限が未設定です。
                </span>
              )}
            </div>

            {/* 確認テスト日時の編集 */}
            <div className="flex flex-col gap-1">
              <label
                htmlFor={`edit-testDate-${item.id}`}
                className="font-medium text-purple-700"
              >
                確認テスト日時
              </label>
              <input
                id={`edit-testDate-${item.id}`}
                type="date"
                value={editedItem.testDate || ''}
                onChange={(e) => setEditedItem({ ...editedItem, testDate: e.target.value })}
                className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300"
                placeholder="例: 2024-12-15"
              />
              {!editedItem.testDate && (
                <span className="text-purple-600 text-sm">
                  確認テスト日時が未設定です。
                </span>
              )}
            </div>

            {/* スコアの編集 */}
            <div className="flex flex-col gap-1">
              <label
                htmlFor={`edit-testScore-${item.id}`}
                className="font-medium text-purple-700"
              >
                スコア
                <span className="text-purple-700 ml-1">(0〜100)</span>
              </label>
              <input
                id={`edit-testScore-${item.id}`}
                type="number"
                value={editedItem.testScore || ''}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === '' ||
                    (Number(value) >= 0 && Number(value) <= 100)
                  ) {
                    setEditedItem({ ...editedItem, testScore: value });
                  }
                }}
                placeholder="例: 85"
                className={`w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-purple-300 ${
                  (editedItem.testScore < 0 || editedItem.testScore > 100) &&
                  'border-pink-500'
                }`}
                min="0"
                max="100"
              />
              {(editedItem.testScore < 0 || editedItem.testScore > 100) && (
                <span className="text-pink-600 text-sm">
                  スコアは0から100の範囲で入力してください。
                </span>
              )}
            </div>
          </div>

          {/* 保存、削除、キャンセルボタンを同じ行に配置 */}
          <div className="flex justify-end mt-4 space-x-2">
            <button
              onClick={handleSave}
              className="bg-purple-300 text-purple-800 px-5 py-2 rounded hover:bg-purple-400"
              aria-label="チェック項目保存ボタン"
            >
              保存
            </button>
            <button
              onClick={handleDelete}
              className="bg-pink-300 text-pink-800 px-5 py-2 rounded hover:bg-pink-400"
              aria-label="チェック項目削除ボタン"
            >
              削除
            </button>
            <button
              onClick={handleCancel}
              className="bg-purple-100 text-purple-700 px-5 py-2 rounded hover:bg-purple-200"
              aria-label="チェック項目編集キャンセルボタン"
            >
              キャンセル
            </button>
          </div>
        </div>
      ) : (
        // 通常表示
        <div className="flex flex-col gap-3">
          {/* チェック項目 */}
          <div className="flex items-center">
            {/* チェックボックス */}
            <button
              className="text-purple-500 mr-2"
              onClick={onCheck}
              aria-label={item.checked ? 'チェックを外す' : 'チェックする'}
            >
              {item.checked ? (
                <CheckSquare size={20} />
              ) : (
                <Square size={20} />
              )}
            </button>
            <span
              className={`block font-bold ${
                item.checked ? 'line-through text-purple-400' : 'text-purple-800'
              } whitespace-pre-wrap break-words overflow-hidden`}
              style={{ wordBreak: 'break-word' }}
            >
              {item.text && item.text.trim() !== '' ? (
                <Linkify
                  options={{
                    ...linkifyOptions,
                    className: 'text-purple-500 underline break-words',
                  }}
                >
                  {item.text}
                </Linkify>
              ) : (
                <span className="text-purple-500 italic">未設定</span>
              )}
            </span>
          </div>

          {/* 宿題期限と先生確認 */}
          <div className="flex flex-wrap items-start mt-2">
            <div className="flex flex-col mr-4">
              <span className="font-medium text-purple-700">宿題期限</span>
              <span
                className="p-2 bg-transparent text-purple-700 text-center text-base font-bold min-h-[24px]"
                aria-label={`宿題期限: ${item.dueDate || '未設定'}`}
              >
                {item.dueDate || (
                  <span className="text-sm italic">未設定</span>
                )}
              </span>
            </div>

            <div className="flex flex-col">
              {isTeacher ? (
                <TeacherCheckButton onTeacherCheck={onTeacherCheck} item={item} />
              ) : (
                <TeacherCheckDisplay item={item} />
              )}
            </div>
          </div>

          {/* 確認テスト日時、スコア */}
          <div className="flex flex-wrap items-start mt-2">
            {/* 確認テスト日時 */}
            <div className="flex flex-col mr-4">
              <span className="font-medium text-purple-700">
                確認テスト日時
              </span>
              <span
                className="p-2 bg-transparent text-purple-700 text-center text-base font-bold min-h-[24px]"
                aria-label={`確認テスト日時: ${item.testDate || '未設定'}`}
              >
                {item.testDate || (
                  <span className="text-sm italic">未設定</span>
                )}
              </span>
            </div>

            {/* スコア */}
            <div className="flex flex-col">
              <span className="font-medium text-purple-700">スコア</span>
              <span
                className={`p-2 bg-transparent text-purple-700 text-center text-base font-bold min-h-[24px]`}
                aria-label={`スコア: ${item.testScore || '未設定'}`}
              >
                {item.testScore || (
                  <span className="text-sm italic">未設定</span>
                )}
              </span>
            </div>
          </div>

          {/* 最終確認 */}
          <div className="flex flex-wrap items-center mt-2">
            <div className="flex flex-col">
              <span className="font-medium text-purple-700 text-base">
                最終確認
              </span>
              {isTeacher ? (
                <PassFailButton onPassFail={onPassFail} item={item} />
              ) : (
                <PassFailDisplay item={item} />
              )}
            </div>
          </div>

          {/* 編集ボタン（教師のみ） */}
          {isTeacher && (
            <div className="flex justify-end mt-2">
              <button
                onClick={() => setIsEditing(true)}
                className="text-purple-500 hover:text-purple-700"
                aria-label="項目を編集"
              >
                <Edit2 size={20} />
              </button>
            </div>
          )}
        </div>
      )}

      {/* 確認ダイアログの追加 */}
      {confirmDialog && (
        <ConfirmDialog
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
        />
      )}
    </div>
  );
};

TaskItem.propTypes = {
  item: PropTypes.object.isRequired,
  onCheck: PropTypes.func.isRequired,
  onTeacherCheck: PropTypes.func.isRequired,
  isTeacher: PropTypes.bool.isRequired,
  onPassFail: PropTypes.func.isRequired,
  onUpdateItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};

export default TaskItem;
